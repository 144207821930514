import { SvsWalletService, SvsWriterApi } from '@storyverseco/svs-navbar';
import { AdminService } from '../admin/AdminService';
import { MockAdminService } from '../admin/MockAdminService';
import { AnalyticsService } from '../analytics/AnalyticsService';
import { MockAnalyticsService } from '../analytics/MockAnalyticsService';
import { ApiType } from '../ApiType';
import { ChainalysisService } from '../chainalysis/ChainalysisService';
import { MockChainalysisService } from '../chainalysis/MockChainalysisService';
import { EmailSubService } from '../emailsub/EmailSubService';
import { MockEmailSubService } from '../emailsub/MockEmailSubService';
import { MetadataService } from '../metadata/MetadataService';
import { MockMetadataService } from '../metadata/MockMetadataService';
import { MockStorageService } from '../mock/MockStorageService';
import { MockPlotsService } from '../plots/MockPlotsService';
import { PlotsService } from '../plots/PlotsService';
import { MockSaleService } from '../sale/MockSaleService';
import { SaleService } from '../sale/SaleService';
import { MockTokenproofService } from '../tokenproof/MockTokenproofService';
import { TokenproofService } from '../tokenproof/TokenproofService';
import { MockUserService } from '../user/MockUserService';
import { UserService } from '../user/UserService';
import { MockWalletService } from '../wallet/MockWalletService';
import { MockWriterService } from '../writer/MockWriterService';
import { BaseSuite } from './BaseSuite';
import { registerSuite } from './Suite';
import { DelegatecashService } from '@services/delegatecash/DelegatecashService';
import { MockDelegatecashService } from '@services/delegatecash/MockDelegatecashService';

class MockSuite extends BaseSuite {
  readonly apiType = ApiType.Mock;

  private _mockStorageService: MockStorageService;
  private _userService: MockUserService;
  private _adminService: MockAdminService;
  private _metadataService: MockMetadataService;
  private _plotsService: MockPlotsService;
  private _analyticsService: MockAnalyticsService;
  private _chainalysisService: MockChainalysisService;
  private _walletService: MockWalletService;
  private _writerService: MockWriterService;
  private _saleService: MockSaleService;
  private _tokenproofService: MockTokenproofService;
  private _emailSubService: MockEmailSubService;
  private _delegatecashService: MockDelegatecashService;

  get mockStorageService(): MockStorageService {
    if (!this._mockStorageService) {
      this._mockStorageService = new MockStorageService();
    }
    return this._mockStorageService;
  }

  get userService(): UserService {
    if (!this._userService) {
      this._userService = new MockUserService(this.mockStorageService, this.mainSuite.chainalysisService);
    }
    return this._userService;
  }
  get adminService(): AdminService {
    if (!this._adminService) {
      this._adminService = new MockAdminService(this.mockStorageService, this.mainSuite.metadataService, this.mainSuite.userService);
    }
    return this._adminService;
  }
  get metadataService(): MetadataService {
    if (!this._metadataService) {
      this._metadataService = new MockMetadataService(this.mockStorageService);
    }
    return this._metadataService;
  }
  get plotsService(): PlotsService {
    if (!this._plotsService) {
      this._plotsService = new MockPlotsService(this.mockStorageService, this.mainSuite.userService);
    }
    return this._plotsService;
  }
  get analyticsService(): AnalyticsService {
    if (!this._analyticsService) {
      this._analyticsService = new MockAnalyticsService();
    }
    return this._analyticsService;
  }
  get chainalysisService(): ChainalysisService {
    if (!this._chainalysisService) {
      this._chainalysisService = new MockChainalysisService(this.mockStorageService);
    }
    return this._chainalysisService;
  }
  get walletService(): SvsWalletService {
    if (!this._walletService) {
      this._walletService = new MockWalletService(this.mockStorageService);
    }
    return this._walletService;
  }
  get writerService(): SvsWriterApi {
    if (!this.writerService) {
      this._writerService = new MockWriterService(this.mockStorageService);
    }
    return this._writerService;
  }
  get saleService(): SaleService {
    if (!this._saleService) {
      this._saleService = new MockSaleService(this.mockStorageService);
    }
    return this._saleService;
  }
  get tokenproofService(): TokenproofService {
    if (!this._tokenproofService) {
      this._tokenproofService = new MockTokenproofService(this.analyticsService, this.saleService);
    }
    return this._tokenproofService;
  }
  get emailSubService(): EmailSubService {
    if (!this._emailSubService) {
      this._emailSubService = new MockEmailSubService();
    }
    return this._emailSubService;
  }
  get delegatecashService(): DelegatecashService {
    if (!this._delegatecashService) {
      this._delegatecashService = new MockDelegatecashService(this.analyticsService, this.saleService);
    }
    return this._delegatecashService;
  }
}

export const mockSuite = new MockSuite();
registerSuite(mockSuite);
