import { debug } from '@common/LogWrapper';
import { LoadState } from '@common/LoadState';
import Sale from '@common/Sale';
import { MBNft, TpHolding } from '@services/sale/SaleService';
import { Action, generateStoreContext } from '../GenerateContext';
import { MintActionType } from './MintActionType';
import { SaleState } from '@common/SaleState';
import { StoryKey } from '@storyverseco/svs-types';
import { normalizeStoryKey, StoryKeyObject } from '@common/StoryKeyUtils';

const log = debug('app:context:MintContext');

export enum MintPageState {
  Idle,
  NoSale,
  Errored,
  PreWhitelist,
  Whitelist,
  WhitelistChecking,
  WhitelistChecked,
  WhitelistNoSpots,
  PreMint,
  Mint,
  MintEntry,
  MintShare,
  Minting,
  Minted,
  MintNoSupply,
  PostMint,
}

export enum MintCheckType {
  None,
  Metamask,
  Tokenproof,
  Manual,
}

export enum MintTwitterAvailState {
  Idle, // not checked
  Available,
  Unavailable,
}

export type MintState = {
  loadState: LoadState;
  pageState: MintPageState;
  forcedPage: boolean;
  sale: Sale | null;
  saleState: SaleState;
  checkType: MintCheckType;
  checkError: string | null;
  walletToCheck: string | null;
  signUpEmail: string | null;
  signUpTwitter: string | null;
  eligibleAdded: number;
  eligibleTokens: number;
  eligibleState: LoadState;
  storyKey: StoryKey | null;
  storyViewerLink: string | null;
  coldWallet: string | null;
  hotWallet: string | null;
  mintAndSig: {
    rawMessage: [string, string, number, number];
    signature: string;
  } | null;
  holdings: TpHolding[] | null;
  mintedNfts: MBNft[] | null;
  mintedError: string | null;
  alreadyMintShared: boolean;
  nftImageMap: Record<string, string>;
  signedTokens: string[] | null;
  skipToShare: boolean;
  platform?: string;
  collectionMintTitle?: string;
  collectionMintSubtitle?: string;
  winner: boolean | null;
  twitterAvailState: MintTwitterAvailState;
};

const initialState: MintState = {
  loadState: LoadState.Idle,
  pageState: MintPageState.Idle,
  forcedPage: false,
  sale: null,
  saleState: SaleState.None,
  checkType: MintCheckType.None,
  checkError: null,
  walletToCheck: null,
  signUpEmail: null,
  signUpTwitter: null,
  eligibleAdded: 0,
  eligibleTokens: 0,
  eligibleState: LoadState.Idle,
  storyKey: null,
  storyViewerLink: null,
  coldWallet: null,
  hotWallet: null,
  mintAndSig: null,
  holdings: null,
  mintedNfts: null,
  mintedError: null,
  alreadyMintShared: false,
  nftImageMap: {},
  signedTokens: null,
  skipToShare: false,
  winner: null,
  twitterAvailState: MintTwitterAvailState.Idle,
};

export type MintActionProps =
  | {
      type: MintActionType.UpdateLoadState;
      loadState: LoadState;
    }
  | {
      type: MintActionType.UpdateMintPageState;
      pageState: MintPageState;
      forced?: boolean;
    }
  | {
      type: MintActionType.UpdateSale;
      sale: Sale | null | undefined;
      saleState?: SaleState;
    }
  | {
      type: MintActionType.UpdateCheckType;
      checkType: MintCheckType;
    }
  | {
      type: MintActionType.UpdateWalletToCheck;
      walletAddress: string;
    }
  | {
      type: MintActionType.StartCheck;
      checkType: MintCheckType;
      pageState?: MintPageState;
      walletAddress?: string | null;
      email?: string | null;
    }
  | {
      type: MintActionType.CheckFailed;
      errorMsg?: string | null;
    }
  | {
      type: MintActionType.UpdateEligibility;
      eligibleAdded?: number;
      eligibleTokens?: number;
      eligibleState?: LoadState;
      storyKey?: StoryKey | StoryKeyObject | string | null;
    }
  | {
      type: MintActionType.UpdateColdWallet;
      wallet?: string | null;
    }
  | {
      type: MintActionType.UpdateHotWallet;
      wallet?: string | null;
    }
  | {
      type: MintActionType.StartMintEntry;
      coldWallet?: string | null;
      hotWallet?: string | null;
      email?: string | null;
      twitter?: string | null;
      skipToShare?: boolean;
    }
  | {
      type: MintActionType.UpdateMintAndSig;
      mintAndSig?: MintState['mintAndSig'];
      holdings?: MintState['holdings'];
      storyKey?: StoryKey | StoryKeyObject | string | null;
      nftImageMap?: Record<string, string> | null;
      signedTokens?: string[] | null;
      platform?: string;
    }
  | {
      type: MintActionType.UpdateMintedNfts;
      mintedNfts?: MintState['mintedNfts'];
    }
  | {
      type: MintActionType.UpdateAlreadyShared;
      shared: boolean;
    }
  | {
      type: MintActionType.UpdateMintedError;
      errorMsg: string | null | undefined;
    }
  | {
      type: MintActionType.StartMintShare;
      email?: string | null;
      twitter?: string | null;
    }
  | {
      type: MintActionType.StartMint;
      shared: boolean;
    }
  | {
      type: MintActionType.MintFinished;
      errorMsg?: string | null;
    }
  | {
      type: MintActionType.MintFailed;
      errorMsg?: string | null;
    }
  | {
      type: MintActionType.UpdateMintTitles;
      collectionMintTitle?: string;
      collectionMintSubtitle?: string;
    }
  | {
      type: MintActionType.UpdateWinner;
      winner: boolean | null;
    }
  | {
      type: MintActionType.UpdateTwitterAvailState;
      twitterAvailState: MintTwitterAvailState;
    }
  | {
      type: MintActionType.UpdateStoryViewerLink;
      storyViewerLink: string | null;
    }
  | {
      type: MintActionType.Reset;
    };

function reducer(state: MintState, action: Action<MintActionType, MintActionProps>): MintState {
  let pageState: MintPageState;
  switch (action.type) {
    case MintActionType.UpdateLoadState:
      return {
        ...state,
        loadState: action.loadState,
      };
    case MintActionType.UpdateMintPageState:
      return {
        ...state,
        pageState: action.pageState,
        forcedPage: action.forced ?? state.forcedPage,
      };
    case MintActionType.UpdateSale:
      return {
        ...state,
        sale: action.sale ?? null,
        saleState: action.saleState ?? SaleState.None,
      };
    case MintActionType.UpdateCheckType:
      return {
        ...state,
        checkType: action.checkType,
      };
    case MintActionType.UpdateWalletToCheck:
      return {
        ...state,
        walletToCheck: action.walletAddress,
      };
    case MintActionType.StartCheck:
      if (action.pageState) {
        pageState = action.pageState;
      } else if (state.pageState === MintPageState.Whitelist) {
        pageState = MintPageState.WhitelistChecking;
      } else if (state.pageState === MintPageState.Mint) {
        pageState = MintPageState.Minting;
      } else {
        log(`action ${action.type} error: Unexpected current page (${state.pageState})`);
        throw new Error('Unexpected current page');
      }
      return {
        ...state,
        pageState,
        checkType: action.checkType,
        walletToCheck: action.walletAddress ?? null,
        signUpEmail: action.email ?? null,
      };
    case MintActionType.CheckFailed:
      return {
        ...state,
        pageState: MintPageState.Whitelist,
        checkType: MintCheckType.None,
        checkError: action.errorMsg ?? null,
        walletToCheck: null,
      };
    case MintActionType.UpdateEligibility:
      return {
        ...state,
        eligibleAdded: action.eligibleAdded ?? state.eligibleAdded,
        eligibleState: action.eligibleState ?? state.eligibleState,
        eligibleTokens: action.eligibleTokens ?? state.eligibleTokens,
        storyKey: normalizeStoryKey(action.storyKey) ?? state.storyKey,
      };
    case MintActionType.UpdateColdWallet:
      return {
        ...state,
        coldWallet: action.wallet ?? null,
      };
    case MintActionType.UpdateHotWallet:
      return {
        ...state,
        hotWallet: action.wallet ?? null,
      };
    case MintActionType.StartMintEntry:
      return {
        ...state,
        pageState: MintPageState.MintEntry,
        coldWallet: action.coldWallet ?? null,
        hotWallet: action.hotWallet ?? null,
        signUpEmail: action.email ?? state.signUpEmail,
        signUpTwitter: action.twitter ?? state.signUpTwitter,
        mintedError: null,
        skipToShare: action.skipToShare ?? false,
      };
    case MintActionType.UpdateMintAndSig:
      return {
        ...state,
        mintAndSig: action.mintAndSig ?? null,
        holdings: action.holdings ?? null,
        storyKey: normalizeStoryKey(action.storyKey || null),
        nftImageMap: action.nftImageMap ?? {},
        signedTokens: action.signedTokens ?? null,
        platform: action.platform,
      };
    case MintActionType.UpdateMintedNfts:
      return {
        ...state,
        mintedNfts: action.mintedNfts ?? null,
      };
    case MintActionType.UpdateAlreadyShared:
      return {
        ...state,
        alreadyMintShared: action.shared,
      };
    case MintActionType.UpdateMintedError:
      return {
        ...state,
        mintedError: action.errorMsg || null, // empty strings are falsy
      };
    case MintActionType.StartMintShare:
      if (state.pageState !== MintPageState.MintEntry) {
        throw new Error('Unexpectedly tried starting mint share while not in mint state');
      }
      return {
        ...state,
        pageState: MintPageState.MintShare,
        signUpEmail: action.email || null, // empty string is falsy
        signUpTwitter: action.twitter || null, // empty string is falsy
        mintedError: null,
        skipToShare: false,
      };
    case MintActionType.StartMint:
      if (state.pageState !== MintPageState.MintShare && state.sale.saleType == 'characterPass') {
        throw new Error('Unexpectedly tried starting mint while not in mint share state');
      }
      return {
        ...state,
        pageState: MintPageState.Minting,
        mintedError: null,
        alreadyMintShared: action.shared,
      };
    case MintActionType.MintFinished:
      if (state.pageState !== MintPageState.Mint && state.pageState !== MintPageState.Minting) {
        throw new Error('Unexpectedly tried finishing mint while not in mint or minting state');
      }
      return {
        ...state,
        pageState: MintPageState.Minted,
        mintedError: action.errorMsg || null, // empty string is falsy
      };
    case MintActionType.MintFailed:
      return {
        ...state,
        pageState: MintPageState.MintShare,
        mintedError: action.errorMsg || null, // empty string is falsy
      };
    case MintActionType.UpdateMintTitles:
      return {
        ...state,
        collectionMintTitle: action.collectionMintTitle,
        collectionMintSubtitle: action.collectionMintSubtitle,
      };
    case MintActionType.UpdateWinner:
      return {
        ...state,
        winner: action.winner,
      };
    case MintActionType.UpdateTwitterAvailState:
      return {
        ...state,
        twitterAvailState: action.twitterAvailState,
      };
    case MintActionType.UpdateStoryViewerLink:
      return {
        ...state,
        storyViewerLink: action.storyViewerLink,
      };
    case MintActionType.Reset:
      return {
        ...state,
        loadState: LoadState.Idle,
        pageState: MintPageState.Idle,
        sale: null,
        saleState: SaleState.None,
        checkType: MintCheckType.None,
        signUpEmail: null,
        signUpTwitter: null,
        eligibleAdded: 0,
        eligibleTokens: 0,
        eligibleState: LoadState.Idle,
        walletToCheck: null,
        storyKey: null,
        storyViewerLink: null,
        coldWallet: null,
        hotWallet: null,
        mintAndSig: null,
        holdings: null,
        mintedError: null,
        mintedNfts: null,
        collectionMintTitle: undefined,
        collectionMintSubtitle: undefined,
        winner: null,
        twitterAvailState: MintTwitterAvailState.Idle,
      };
    default:
      throw new Error(`mint-context: Unknown action type "${(action as any).type}"`);
  }
}

const { StateContext, DispatchContext, ContextProvider, ContextConsumer, withContext, useContextState, useContextDispatch } = generateStoreContext<
  MintState,
  MintActionType,
  MintActionProps
>(reducer, initialState, 'mintState', 'mintDispatch');

export {
  StateContext as MintStateContext,
  DispatchContext as MintDispatchContext,
  ContextProvider as MintProvider,
  ContextConsumer as MintConsumer,
  withContext as withMint,
  useContextState as useMintState,
  useContextDispatch as useMintDispatch,
  MintActionType,
};
