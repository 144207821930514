import React, { useEffect } from 'react';

import { environment } from '../environments/environment';

import { AdminService } from './admin/AdminService';
import { MetadataService } from './metadata/MetadataService';
import { UserService } from './user/UserService';
import { ApiType } from './ApiType';
import { getSuite, setMainSuite, Suite } from './suite/Suite';
import { UserAccount } from '../common/UserAccount';
import { Action } from '../context/GenerateContext';
import { AnalyticsService } from './analytics/AnalyticsService';
import { ChainalysisService } from './chainalysis/ChainalysisService';
import { SvsBridge, SvsWalletService, SvsWriterApi } from '@storyverseco/svs-navbar';
import { TokenproofService } from './tokenproof/TokenproofService';
import { SaleService } from './sale/SaleService';
import { EmailSubService } from './emailsub/EmailSubService';
import { useUserDispatch, useUserState } from './user/UserContext';
import { DelegatecashService } from './delegatecash/DelegatecashService';
import { RestDelegatecashService } from './delegatecash/RestDelegatecashService';

class MainSuite implements Suite {
  get userService(): UserService {
    const apiType = environment.userServiceApi as ApiType;
    return getSuite(apiType).userService;
  }
  get adminService(): AdminService {
    const apiType = environment.adminApi as ApiType;
    return getSuite(apiType).adminService;
  }
  get metadataService(): MetadataService {
    const apiType = environment.metadataApi as ApiType;
    return getSuite(apiType).metadataService;
  }
  get analyticsService(): AnalyticsService {
    const apiType = environment.analyticsApi as ApiType;
    return getSuite(apiType).analyticsService;
  }

  get chainalysisService(): ChainalysisService {
    const apiType = environment.chainalysisApi as ApiType;
    return getSuite(apiType).chainalysisService;
  }

  get navbarService(): SvsBridge {
    const apiType = environment.navbarApi as ApiType;
    return getSuite(apiType).navbarService;
  }

  get walletService(): SvsWalletService {
    const apiType = environment.walletApi as ApiType;
    return getSuite(apiType).walletService;
  }

  get writerService(): SvsWriterApi {
    const apiType = environment.writerApi as ApiType;
    return getSuite(apiType).writerService;
  }

  get saleService(): SaleService {
    const apiType = environment.saleApi as ApiType;
    return getSuite(apiType).saleService;
  }

  get tokenproofService(): TokenproofService {
    const apiType = environment.tokenproofApi as ApiType;
    return getSuite(apiType).tokenproofService;
  }

  get emailSubService(): EmailSubService {
    const apiType = environment.emailSubApi as ApiType;
    return getSuite(apiType).emailSubService;
  }

  get delegatecashService(): DelegatecashService {
    const apiType = environment.delegatecashApi as ApiType;
    return getSuite(apiType).delegatecashService;
  }

  setUserState(state: UserAccount): void {
    this.userService.setUserState(state);
    this.adminService.setUserState(state);
    this.analyticsService.setUserState(state);
  }

  setUserDispatch(dispatch: React.Dispatch<Action>): void {
    this.userService.setDispatch(dispatch);
    this.adminService.setUserDispatch(dispatch);
  }
}

export const mainSuite = new MainSuite();
setMainSuite(mainSuite);

export function MainSuiteSetup() {
  const userState = useUserState();
  const userDispatch = useUserDispatch();

  useEffect(() => {
    mainSuite.setUserState(userState);
  }, [userState]);
  useEffect(() => {
    mainSuite.setUserDispatch(userDispatch);
  }, [userDispatch]);

  return null;
}
