import { isDcToken, DelegatecashService, DcAccount, DcReason, DcStatus, DcToken } from './DelegatecashService';
import { environment } from '@environment';
import { waitForExpectedValue, waitForMs } from '@common/WaitUtils';
import { LoginState, loginStateFinished, loginStateInProgress } from '@common/LoginState';
import { ConditionWaiter } from '@common/ConditionWaiter';
import { safeLocalStorage } from '@common/SafeLocalStorage';
import { daysToMs, hoursToMs, minutesToMs } from '@common/NumberUtils';
import { LoadState } from '@common/LoadState';
import { AnalyticsService } from '../analytics/AnalyticsService';
import { SaleService } from '../sale/SaleService';
import { AnalyticsEventName, AnalyticsUsageName } from '../analytics/AnalyticsEventName';
import { debug } from '@common/LogWrapper';
import * as Sentry from '@sentry/react';
import { MintPageState } from '@context/mint/MintContext';
import { pipelineApiCall, PipelineEndpoint } from '@common/SvsRestApi';

const log = debug('app:services:RestDelegatecashService');
const delegateCashLocalStorageSessionKey = 'svs.mainsite.dc.s:1.0.0';
const dcExpiresIn = minutesToMs(3);
const dcExpireMaxRetries = 1;
const dcRetryOnExpire = false;

type DcPipelineResult = {
  address: string;
  nonce: string;
  // we might need this for future
  accounts?: any[];
  storyKey: string;
  sig: string;
  signedTokens: string[];
  excludedTokens: string[];
  rawMessage: any[];
};

type DcSession = {
  lastVerified: string;
  dcResult: DcPipelineResult;
};

/*
{
  rawMessage: messageTuple,
  sig: signature,
  storyKey: shareStoryKey,
  signedTokens: Array.from(signableTokens),
  excludedTokens: Array.from(dcTokens.excludedTokens),
  nonce: nonce // none to be used by frontend for the later
};
*/
function isDcPipelineResult(obj: any): obj is DcPipelineResult {
  return (
    obj &&
    typeof obj === 'object' &&
    typeof obj.nonce === 'string' &&
    typeof obj.storyKey === 'string' &&
    typeof obj.sig === 'string' &&
    Array.isArray(obj.signedTokens) &&
    obj.signedTokens.every((item: any) => typeof item === 'string') &&
    Array.isArray(obj.rawMessage)
  );
}

function isDcSession(obj: any): obj is DcSession {
  return obj && typeof obj === 'object' && typeof obj.lastVerified === 'string' && isDcPipelineResult(obj.dcResult);
}

function isInitted(loadState: LoadState): boolean {
  return loadState === LoadState.Loaded;
}

function isValidDate(d: any): boolean {
  return d instanceof Date && !isNaN(d.getTime());
}

export class RestDelegatecashService implements DelegatecashService {
  private nonce: string = null;
  private status: DcStatus | string = DcStatus.Idle;
  private reason: DcReason | string = DcReason.Idle;
  private address: string = null;

  private loginWaiter = new ConditionWaiter(LoginState.Idle, loginStateFinished);
  private initWaiter = new ConditionWaiter(LoadState.Idle, isInitted);

  private mainAccount: DcAccount = null;
  private accountMap: Record<string, DcAccount> = {};

  constructor(private analyticsService: AnalyticsService, private saleService: SaleService) {}

  async init(opts?: { fromLogin?: boolean }) {
    if (this.initted) {
      return;
    }

    if (this.initWaiter.get() === LoadState.Loading) {
      await this.initWaiter.wait();
      return;
    }
    this.initWaiter.set(LoadState.Loading);

    // determine if we should retry on expire. dcRetryOnExpire is an overal override
    const retryOnExpire = (opts?.fromLogin ?? false) && dcRetryOnExpire;

    // load from local storage
    // todo: figure out caching
    log(`init: loading from local storage: retryOnExpire ${retryOnExpire}`);
    await this.initParseAccountsFromStorage({ retryOnExpire });

    if (this.mainAccount) {
      this.loginWaiter.set(LoginState.LoggedIn);
    } else {
      this.loginWaiter.set(LoginState.LoggedOut);
    }

    this.initWaiter.set(LoadState.Loaded);
  }

  isLoggedIn(): boolean {
    return this.loginState === LoginState.LoggedIn;
  }

  getAccount(address?: string): DcAccount | null {
    console.log('delegate cash:getAccount', address, this.mainAccount);
    if (address) {
      return this.accountMap[address] ?? null;
    }
    return this.mainAccount;
  }

  getAccountMap(): Record<string, DcAccount> {
    return this.accountMap;
  }

  getNonce() {
    return this.nonce;
  }

  getStatus(): string | null {
    return this.status;
  }

  getReason(): string | null {
    return this.reason;
  }

  getSignResponse() {
    const session = this.getSessionfromStorage();
    return session?.dcResult || {};
  }

  getAnalyticsUsage(mintPageState: MintPageState): AnalyticsUsageName {
    let analyticsUsage: AnalyticsUsageName;

    switch (mintPageState) {
      case MintPageState.PreWhitelist:
      case MintPageState.Whitelist:
      case MintPageState.WhitelistChecking:
      case MintPageState.WhitelistChecked:
      case MintPageState.WhitelistNoSpots:
        analyticsUsage = 'whitelist';
        break;
      case MintPageState.PreMint:
      case MintPageState.Mint:
      case MintPageState.MintEntry:
      case MintPageState.MintShare:
      case MintPageState.Minting:
      case MintPageState.Minted:
      case MintPageState.MintNoSupply:
      case MintPageState.PostMint:
        analyticsUsage = 'mint';
        break;
      default:
        analyticsUsage = 'unknown';
        break;
    }

    return analyticsUsage;
  }

  /**
   * This is safe to call multiple times, it won't duplicate fetches during the login process
   * @param opts
   * @returns
   */
  /**
   *    mintPageState: mintState.pageState,
        tokenType: mintState.sale?.tokenType,
        walletAddress: userState.address,
   */
  async loginAndSign(opts?: {
    appId?: string;
    env?: string;
    force?: boolean;
    mintPageState?: MintPageState;
    tokenType?: string;
    walletAddress?: string;
  }): Promise<DcAccount | null> {
    this.address = opts?.walletAddress ?? null;
    if (!this.initted) {
      log('loginAndSign: init not called yet');
      await this.init({ fromLogin: true });
    }

    if (!opts?.force && this.loginState === LoginState.LoggedIn) {
      return this.mainAccount;
    }
    if (loginStateInProgress(this.loginState)) {
      await this.loginWaiter.wait();
      return this.mainAccount;
    }
    const params = {
      usage: this.getAnalyticsUsage(opts?.mintPageState ?? MintPageState.Idle),
      tokenType: opts.tokenType,
    };
    this.analyticsService.track(AnalyticsEventName.DelegatecashStart, params);
    this.mainAccount = null;
    this.status = DcStatus.Idle;
    this.reason = DcReason.Idle;
    this.accountMap = {};
    this.loginWaiter.set(LoginState.LoggingIn);

    await this.loginAndSignAndStoreSession(opts.tokenType, opts.walletAddress);
    await this.initParseAccountsFromStorage();

    if (this.mainAccount) {
      this.loginWaiter.set(LoginState.LoggedIn);
      this.analyticsService.track(AnalyticsEventName.DelegatecashSuccess, params);
      Sentry.setContext('delegatecash', {
        wallet: this.mainAccount.address,
        nonce: this.nonce,
        reason: this.reason,
        status: this.status,
      });
    } else {
      this.analyticsService.track(AnalyticsEventName.DelegatecashError, {
        status: this.status,
        reason: this.reason,
        ...params,
      });
      Sentry.setContext('delegatecash', {
        wallet: this.mainAccount?.address,
        nonce: this.nonce,
        status: this.status,
        reason: this.reason,
      });
      this.loginWaiter.set(LoginState.LoggedOut);
    }
    return this.mainAccount;
  }

  async logout(): Promise<void> {
    this.status = DcStatus.Idle;
    this.reason = DcReason.Idle;
    this.mainAccount = null;
    this.accountMap = {};
    this.loginWaiter.set(LoginState.LoggedOut);
    safeLocalStorage.removeItem(delegateCashLocalStorageSessionKey);
  }

  // this will login user and sign for mint at the same time
  private async loginAndSignAndStoreSession(tokenType: string, walletAddress?: string) {
    const result = await pipelineApiCall({
      method: 'POST',
      endpoint: PipelineEndpoint.Delegatecash,
      body: {
        mintTo: walletAddress,
      },
      tokens: {
        walletAddress,
        tokenType,
      },
      retryOpts: {
        maxRetries: 3,
        retryDelay: 1000,
        retryCallback(reason, retries, maxRetries) {
          log(`loginAndSignAndStoreSession: failed. retrying...(${retries}/${maxRetries}) (${reason?.message ?? reason})`);
        },
      },
    });

    const dcResult = {
      address: walletAddress,
      ...result,
    };
    console.log('LOGGED IN', dcResult);
    // TODO: figure out

    if (!isDcPipelineResult(dcResult)) {
      log('loginAndSignAndStoreSession: invalid data format');
      safeLocalStorage.removeItem(delegateCashLocalStorageSessionKey);
      return;
    }

    const session: DcSession = {
      lastVerified: new Date().toISOString(),
      dcResult,
    };
    const sessionStr = JSON.stringify(session);
    safeLocalStorage.setItem(delegateCashLocalStorageSessionKey, window.btoa(sessionStr));
  }

  private getSessionfromStorage(): DcSession | null {
    const sessionStrB64 = safeLocalStorage.getItem(delegateCashLocalStorageSessionKey);
    if (!sessionStrB64) {
      return null;
    }

    let sessionStr: string;
    try {
      sessionStr = window.atob(sessionStrB64);
    } catch (e) {
      log('invalid 64 string from stored session text:', sessionStr, e.message);
      return null;
    }

    let session: unknown;
    try {
      session = JSON.parse(sessionStr);
    } catch (e) {
      log('invalid json from stored session text:', sessionStr, e.message);
      return null;
    }

    if (!isDcSession(session)) {
      log('invalid session format:', sessionStr);
      return null;
    }

    return session;
  }

  getSignedTokens() {
    return this.mainAccount?.tokens || [];
  }

  private verifyStatus() {
    if (this.status === DcStatus.Rejected) {
      log(`Rejected (${this.status}, ${this.reason})`);
      return false;
    }
    if (this.status === DcStatus.Closed) {
      log('User canceled');
      return false;
    }
    if (this.status !== DcStatus.Authenticated) {
      log('Unexpected status:', this.status, this.reason);
      return false;
    }

    return true;
  }

  private async initParseAccountsFromStorage(opts?: { retryOnExpire?: boolean; currentTries?: number }) {
    console.log('delegate cash:initParseAccountsFromStorage');
    const session = this.getSessionfromStorage();
    if (!session) {
      console.log('delegate cash:initParseAccountsFromStorage: no session');
      return;
    }
    const result = session.dcResult;
    if (!result) {
      console.log('delegate cash:initParseAccountsFromStorage: no result');
      return;
    }
    // todo: check for state address
    console.log('delegate cash:initParseAccountsFromStorage: main account is here', this.address, result.address);
    this.mainAccount = {
      address: result.address,
      tokens: result.signedTokens,
    };

    this.nonce = result.nonce;

    const lastVerifiedStr = session.lastVerified;

    let lastVerified: Date = undefined;
    if (lastVerifiedStr) {
      lastVerified = new Date(lastVerifiedStr);
    }
    if (!isValidDate(lastVerified)) {
      log('Not a valid last verified date');
    }

    if (!isValidDate(lastVerified)) {
      log('Not a valid last verified date (from original result)');
      return;
    }

    log('lastVerified', lastVerified);

    if (Date.now() >= lastVerified.getTime() + dcExpiresIn) {
      const retryOnExpire = opts?.retryOnExpire ?? dcRetryOnExpire;
      const currentTries = opts?.currentTries ?? 0;
      if (retryOnExpire && currentTries < dcExpireMaxRetries) {
        log('Expired. Trying to get new data from existing nonce');
        await this.loginAndSignAndStoreSession('booo');
        const verified = this.verifyStatus();
        if (verified) {
          await this.initParseAccountsFromStorage({ retryOnExpire: opts?.retryOnExpire, currentTries: currentTries + 1 });
        } else {
          log('Expired or rejected.');
          safeLocalStorage.removeItem(delegateCashLocalStorageSessionKey);
        }
      } else {
        log('Expired.');
        this.nonce = null;
        if (retryOnExpire) {
          // only clear if we retried already
          safeLocalStorage.removeItem(delegateCashLocalStorageSessionKey);
        }
      }
      return;
    }
  }

  private get loginState() {
    return this.loginWaiter.get();
  }

  private get initted() {
    return isInitted(this.initWaiter.get());
  }
}
