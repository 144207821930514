import { environment } from '@environment';
import { MintActionType, MintPageState, useMintDispatch, useMintState } from '@context/mint/MintContext';
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { mainSuite } from '@services/ServiceFactory';
import { useUserState } from '@services/user/UserContext';
import { LoginState, loginStateInProgress } from '@common/LoginState';
import { debug } from '@common/LogWrapper';
import { useAppState } from '@context/AppContext';
import { TwitterAuthStepButton } from '../components/TwitterAuthButton';
import { LoginButton } from '../components/WalletConnectAndDelegateCashLoginButton';
import { SearchParam } from '@common/SearchParam';

const log = debug('app:pages:MintSubPage:CharPassAuthSection:RestDelegatecashService');

export function CharPassAuthSection({ showSpinner }: { showSpinner: (show: boolean) => void }) {
  const appState = useAppState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const userState = useUserState();
  const [redirectAuthProcessed, setRedirectAuthProcessed] = useState(false);
  const { delegatecashService, chainalysisService, navbarService } = mainSuite;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { twitterService } = navbarService.api;
  const [canMint, setCanMint] = useState(false);

  // logic to skip to share to mint page if redirected from twitter auth
  useEffect(() => {
    if (redirectAuthProcessed) {
      log('redirectAuthProcessed');
      return;
    }
    if (!appState?.initialSearchParams) {
      log('no initialSearchParams');
      return;
    }
    if (!appState.initialSearchParams.has(SearchParam.Auth)) {
      log('no auth');
      return;
    }

    // using pre redirect data as a prerequisite to detect redirect behavior
    //const preRedirectData = loadPreRedirectData();
    const preRedirectData = null;
    log('preRedirectData', preRedirectData);
    if (!preRedirectData) {
      log('no preRedirectData');
      //setRedirectAuthProcessed(true);
      //return;
    }

    if (userState.loginState === LoginState.Idle) {
      showSpinner(true);
      return;
    }
    if (loginStateInProgress(userState.loginState)) {
      showSpinner(true);
      return;
    }
    if (userState.loginState === LoginState.LoggedOut) {
      showSpinner(false);
      setRedirectAuthProcessed(true);
      return;
    }

    let stale = false;
    let removePrd = false;
    async function startMintEntry() {
      const coldAccount = delegatecashService.getAccount();
      const coldWallet = coldAccount.address;
      const hotWallet = userState.address;

      const isSafe = await chainalysisService.isWalletAddressSafe(coldWallet);
      if (!isSafe || !userState.caSafe) {
        // todo show error page
        log('Wallet address is not safe');
      } else {
        log('Wallet address is safe');
      }

      log('going to StartMintEntry');
      removePrd = true;
      mintDispatch({
        type: MintActionType.StartMintEntry,
        coldWallet,
        hotWallet,
        email: preRedirectData?.email,
        twitter: preRedirectData?.twitter,
        skipToShare: true,
      });
    }

    showSpinner(true);
    log('go to all checks');
    Promise.all([
      twitterService.auth.canWrite(),
      delegatecashService.init(), // init existing session if not expired
    ]).then(([loggedIn]) => {
      if (stale) {
        return;
      }
      if (!loggedIn) {
        return;
      }
      if (!delegatecashService.isLoggedIn()) {
        return;
      }
      log('all checks done');
      setRedirectAuthProcessed(true);
      setCanMint(true);
      console.log('unblock submit button');
      // startMintEntry();
    });
    return () => {
      stale = true;
      if (removePrd && preRedirectData) {
        log('about to delete prd');
        //removePreRedirectData();
      }
    };
  }, [appState?.initialSearchParams, userState, redirectAuthProcessed]);

  async function startMintEntry() {
    const coldAccount = delegatecashService.getAccount();
    const coldWallet = coldAccount.address;
    const hotWallet = userState.address;

    const isSafe = await chainalysisService.isWalletAddressSafe(coldWallet);
    if (!isSafe || !userState.caSafe) {
      // todo show error page
      log('Wallet address is not safe');
    } else {
      log('Wallet address is safe');
    }

    log('going to StartMintEntry');
    // removePrd = true;
    mintDispatch({
      type: MintActionType.StartMintEntry,
      coldWallet,
      hotWallet,
      email: '',
      twitter: '',
      //skipToShare: true,
    });
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    startMintEntry();
  };

  const onAuthorized = () => async (authorized: boolean) => {
    log('onAuthorized', authorized);
    setCanMint(authorized);
  };

  return (
    <form onSubmit={onSubmit}>
      <LoginButton showSpinner={showSpinner} />
      <TwitterAuthStepButton showSpinner={showSpinner} onAuthorized={onAuthorized()} charPass />
      <div className="buttons-con">
        <button className="mint-btn-cta" type="submit" disabled={!canMint}>
          Submit
        </button>
      </div>
    </form>
  );
}
