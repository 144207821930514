export enum AnalyticsEventName {
  PageView = 'PageView',
  ButtonPress = 'ButtonPress',
  WalletConnect = 'WalletConnect',
  WalletConnectStart = 'WalletConnectStart',
  WalletConnectError = 'WalletConnectError',
  GameStart = 'GameStart',
  EntryFinal = 'EntryFinal',
  MintStart = 'MintStart',
  MintError = 'MintError',
  MintSuccess = 'MintSuccess',
  MintShareSuccess = 'MintShareSuccess',
  TokenproofStart = 'TokenproofStart',
  TokenproofSuccess = 'TokenproofSuccess',
  TokenproofError = 'TokenproofError',
  WhitelistAddStart = 'WhitelistAddStart',
  WhitelistAddSuccess = 'WhitelistAddSuccess',
  WhitelistAddError = 'WhitelistAddError',
  Share = 'Share',
  SignupSuccess = 'SignupSuccess',
  GenericError = 'Error',
  TwitterAuth = 'TwitterAuth',
  ContestStatus = 'ContestStatus',
  ContestStatusClaim = 'ContestStatusClaim',
  ChainwatcherTimeout = 'ChainwatcherTimeout',
  DelegatecashStart = 'DelegatecashStart',
  DelegatecashSuccess = 'DelegatecashSuccess',
  DelegatecashError = 'DelegatecashError',
}

export enum ShareType {
  NATIVE = 'Native',
  TWITTER = 'Twitter',
  USER_ABORT = 'UserAbort',
  ERROR = 'Error',
}

export type AnalyticsUsageName = 'whitelist' | 'mint' | 'unknown';
