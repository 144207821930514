import type { MintPageState } from '@context/mint/MintContext';

export type DcToken = {
  id: string;
  qty: number;
};

export type DcAccount = {
  address: string;
  tokens: string[];
};

export enum DcStatus {
  Idle = 'idle',
  Authenticated = 'authenticated',
  Rejected = 'rejected',
  Closed = 'closed',
  Unknown = 'unknown',
}

export enum DcReason {
  Idle = 'idle',
  AuthenticatedAccount = 'authenticated_account',
  IneligibleCredential = 'ineligible_credential',
  ExpiredSession = 'expired_session',
  RevokedCredential = 'revoked_credential',
  TamperedCredential = 'tampered_credential',
  ModalClosed = 'modal_closed',
  Unknown = 'unknown',
}

export function isDcToken(obj: any): obj is DcToken {
  return obj && typeof obj === 'object' && typeof obj.id === 'string' && typeof obj.qty === 'number';
}

export function isDcAccount(obj: any): obj is DcAccount {
  return obj && typeof obj === 'object' && typeof obj.address === 'string' && typeof obj.tokens === 'object' && Object.values(obj.tokens).every(isDcToken);
}

export interface DelegatecashService {
  init(): Promise<void>;
  isLoggedIn(): boolean;
  getAccount(address?: string): DcAccount | null;
  getAccountMap(): Record<string, DcAccount>;
  getNonce(): string | null;
  getStatus(): string | null;
  getReason(): string | null;
  loginAndSign(opts?: {
    appId?: string;
    env?: string;
    mintPageState?: MintPageState;
    tokenType?: string;
    walletAddress?: string;
    policyId?: string;
    force?: boolean;
  }): Promise<DcAccount | null>;
  logout(): Promise<void>;
  getSignedTokens(): string[];
  getSignResponse(): any;
}
