import { environment } from '@environment';
import { Page } from '../Page';
import MintLayout from './layouts/MintLayout';
import { MintActionType, MintPageState, MintTwitterAvailState, useMintDispatch, useMintState } from '@context/mint/MintContext';
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import MintVideoLayout from './layouts/MintVideoLayout';
import VimeoReactPlayer from '@components/vimeo-react-player/VimeoReactPlayer';
import './MintSubPage.scss';
import GradientTitle from '@components/GradientTitle';
import { mainSuite } from '@services/ServiceFactory';
import { useUserState } from '@services/user/UserContext';
import { LoginState, loginStateInProgress } from '@common/LoginState';
import TransitioningSection from '@components/transitioning-section';
import { SvsInputText } from '@components/svs-input-text/SvsInputText';
import emailIcon from '@assets/svg/email-icon.svg';
import twitterIcon from '@assets/svg/twitter.svg';
import charPassImg from '@assets/mint/character_pass_placeholder.gif';
import { Link, useLocation } from 'react-router-dom';
import { MintVideoContent, MintVideoContentRef, MintVideoContentType } from './components/MintVideoContent';
import { SvsNavbarEvent, SvsProvider, TwtAuthVersion } from '@storyverseco/svs-navbar';
import { AnalyticsEventName, ShareType } from '@services/analytics/AnalyticsEventName';
import { minutesToMs, secondsToMs } from '@common/NumberUtils';
import { debug } from '@common/LogWrapper';
import { EmailSignUpSection } from './sections/EmailSignUpSection';
import { TextNewLiner } from '@components/text-new-liner/TextNewLiner';
import { defaultVimeoUrl } from './components/MintVideoContent';
import { PremintFaqs } from './sections/PremintFaqs';
import { StoryKey, WalletAddress } from '@storyverseco/svs-types';
import { pipelineApiCall, PipelineEndpoint } from '@common/SvsRestApi';
import { tokenReplace, truncateAddress } from '@common/StringUtils';
import { useAppState } from '@context/AppContext';
import { SearchParam } from '@common/SearchParam';
import safeLocalStorage from '@common/SafeLocalStorage';
import { TwtAuthState } from '@common/TwtAuthState';
import { CharPassAuthSection } from './sections/CharPassAuthSection';
import { getMintErrorReason } from '@common/GetMintErrorReason';

const log = debug('app:pages:MintSubPage');

const preRedirectDataKey = 'svs.mainSite.m.prd:1.0.0';

type PreRedirectData = {
  email?: string | null;
  twitter?: string | null;
};

function isPreRedirectData(obj: any): obj is PreRedirectData {
  return (
    obj &&
    (!('email' in obj) || typeof obj.email === 'string' || obj.email === null) &&
    (!('twitter' in obj) || typeof obj.twitter === 'string' || obj.twitter === null)
  );
}

function loadPreRedirectData(): PreRedirectData | null {
  const b64Data = safeLocalStorage.getItem(preRedirectDataKey);
  if (!b64Data) {
    return null;
  }

  let dataStr: string;
  try {
    dataStr = window.atob(b64Data);
  } catch (e) {
    log('Invalid post redirect data base-64:', e.message);
    return null;
  }

  let data: unknown;
  try {
    data = JSON.parse(dataStr);
  } catch (e) {
    log('Invalid post redirect data json', e.message);
    return null;
  }

  if (!isPreRedirectData(data)) {
    log('Invalid post redirect data format');
    return null;
  }

  return data;
}

function savePreRedirectData(data: PreRedirectData): void {
  const dataStr = JSON.stringify(data);
  const b64Data = window.btoa(dataStr);
  safeLocalStorage.setItem(preRedirectDataKey, b64Data);
}

function removePreRedirectData(): void {
  safeLocalStorage.removeItem(preRedirectDataKey);
}

function getVimeoUrl() {
  const mintState = useMintState();
  return mintState.sale?.saleMedia?.vimeoUrl || defaultVimeoUrl;
}

function getLid(saleType: string) {
  if (saleType !== 'characterPass') {
    return undefined;
  }
  return {
    lid: 's',
  };
}

async function fetchViewerLink(storyKey: StoryKey, queryParams?: Record<string, string>): Promise<string> {
  const data = await pipelineApiCall({
    method: 'GET',
    endpoint: PipelineEndpoint.Preshare,
    tokens: {
      walletAddress: storyKey.address,
      storyId: storyKey.id,
    },
    queryParams,
  });
  let preshareResponse: any;
  if (typeof data === 'string') {
    preshareResponse = JSON.parse(data);
  } else if (data && typeof data === 'object') {
    preshareResponse = data;
  } else {
    throw new Error('Unexpected data type for viewer link');
  }
  if (!preshareResponse?.viewer || typeof preshareResponse.viewer !== 'string') {
    throw new Error('No viewer link');
  }
  return preshareResponse.viewer;
}

function openTwitterShareIntent(message: string): void {
  const url = new URL('https://twitter.com/intent/tweet');
  url.searchParams.append('text', message);
  const urlStr = url.toString();
  window.open(urlStr, '_blank');
}

function doAddressesMatch(addr1: string | null | undefined, addr2: string | null | undefined): boolean {
  // require both addresses to be non-empty and not null or undefined
  return Boolean(addr1 && addr2 && addr1.toLowerCase() === addr2.toLowerCase());
}

function useUserAddress(truncated = true): string | null {
  const userState = useUserState();
  return useMemo(() => {
    if (!userState.loggedIn) {
      return null;
    }
    if (!userState.address) {
      return null;
    }

    return truncated ? truncateAddress(userState.address) : userState.address;
  }, [userState.address, userState.loggedIn, truncated]);
}

function useHotWalletAddress(truncated = true): string | null {
  const mintState = useMintState();
  return useMemo(() => {
    if (!mintState.hotWallet) {
      return null;
    }

    return truncated ? truncateAddress(mintState.hotWallet) : mintState.hotWallet;
  }, [mintState.hotWallet]);
}
const log2 = debug('app:services:RestDelegatecashService:mintSubPage');
function MintStatePage() {
  const appState = useAppState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const userState = useUserState();
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <MintLayout title={mintState.sale.saleName} subtitle="In collaboration with Delegate Cash" className="mint-state-page-layout">
      <MintVideoLayout videoContent={<VimeoReactPlayer url={getVimeoUrl()} autoPlayType="inview" loop />} showSpinner={showSpinner}>
        <GradientTitle>The Mint is now live!</GradientTitle>
        <CharPassAuthSection showSpinner={setShowSpinner} />
      </MintVideoLayout>
      <PremintFaqs />
    </MintLayout>
  );
}

function MintEntryStatePage() {
  const userState = useUserState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const { saleService, delegatecashService } = mainSuite;
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [twitter, setTwitter] = useState(mintState.signUpTwitter ?? '');
  const [email, setEmail] = useState(mintState.signUpEmail ?? '');

  const matchingAddr = doAddressesMatch(userState.address, mintState.hotWallet);
  const userAddress = useUserAddress();
  const hotWalletAddress = useHotWalletAddress();

  // we use form event to take advantage of email validation
  function onSubmit(e?: FormEvent) {
    e?.preventDefault();
    mintDispatch({
      type: MintActionType.StartMintShare,
      email,
      twitter,
    });
  }

  useEffect(() => {
    if (mintState.mintAndSig) {
      return;
    }

    const { coldWallet, hotWallet } = mintState;
    const nonce = delegatecashService.getNonce();
    const signedTokens = delegatecashService.getSignedTokens();
    const response = delegatecashService.getSignResponse();

    if (!coldWallet) {
      setErrorMsg('Missing cold wallet');
      return;
    }

    if (!hotWallet) {
      setErrorMsg('Missing hot wallet');
      return;
    }

    if (!nonce) {
      setErrorMsg('Missing nonce');
      return;
    }

    // OLD
    let stale = false;
    // safe to call multiple times
    Promise.all([
      saleService.fetchNFTContractAddress({
        saleId: mintState.sale.saleId,
      }),
    ])
      .then(([contractAddress]) => {
        if (stale) {
          log('signForMint stale');
          return;
        }

        const nftImageMap = {};
        // defined above
        // const signedTokens = response.signedTokens ?? [];

        // is it safe to confirm here?
        // if (!signedTokens.length) {
        //   throw new Error('We cannot confirm you have the required tokens.');
        // }

        const holdings = signedTokens.map((tokenId) => ({
          id: tokenId,
          contractAddress,
          imageUrl: mintState.sale.pfpPlaceholderUrl || charPassImg,
        }));

        // const account = delegatecashService.getAccount();
        mintDispatch({
          type: MintActionType.UpdateMintAndSig,
          mintAndSig: {
            rawMessage: response.rawMessage,
            signature: response.sig,
          },
          holdings,
          storyKey: response.storyKey,
          nftImageMap,
          platform: response.platform,
        });

        if (mintState.skipToShare) {
          onSubmit();
        }
      })
      .catch((e) => {
        log('Error occurred while signing for mint:', e);
        setErrorMsg(['Error occurred while fetching signature.', 'Please provide this info to developers:', e.message].join('\n'));
      });

    return () => {
      stale = true;
    };
  }, [mintState]);

  if (!mintState.mintAndSig || mintState.skipToShare) {
    if (errorMsg) {
      return (
        <div className="alert alert-danger" role="alert">
          <TextNewLiner text={errorMsg} />
        </div>
      );
    }
    return (
      <div className="mintentry-state-page-loading">
        <GradientTitle>Authenticating. This may take a minute.</GradientTitle>
        <TransitioningSection />
      </div>
    );
  }

  const nftName = mintState.sale.tokenNameSingular;

  return (
    <MintLayout title="Finalize Mint" subtitle="Characters to be minted" className="mintentry-state-page-layout">
      {errorMsg && (
        <div className="alert alert-danger" role="alert">
          {errorMsg}
        </div>
      )}
      <form onSubmit={onSubmit}>
        <div className="mintentry-body">
          <div className="nft-con">
            {mintState.holdings?.map((holding) => (
              <figure key={holding.id} className="nft">
                {holding.imageUrl && <img src={holding.imageUrl} alt={`${nftName} #${holding.id}`} />}
                {!holding.imageUrl && (
                  <div className="no-image">
                    <p>
                      {nftName} #{holding.id}
                    </p>
                  </div>
                )}
                <figcaption>
                  {nftName} #{holding.id}
                </figcaption>
              </figure>
            ))}
          </div>
          {!matchingAddr && (
            <div className="error-notice-con">
              <p className="error-notice">Please connect with the same hot wallet you initially connected with.</p>
              <p className="error-notice">
                (Expected: {hotWalletAddress}, current: {userAddress})
              </p>
            </div>
          )}
          <div className="buttons-con">
            <button className="mint-btn-cta" type="submit" disabled={!matchingAddr}>
              NEXT
            </button>
          </div>
          <div className="notice-con">
            <p className="notice">
              By clicking next, you agree to our <Link to="/tos">Terms of Service</Link> and have read our <Link to="/privacy">Privacy Policy</Link>.
            </p>
          </div>
        </div>
      </form>
    </MintLayout>
  );
}

enum ViewerLoadState {
  Idle,
  Loading,
  LoadExpired,
  Loaded,
}

function MintShareStatePage() {
  const { analyticsService, navbarService } = mainSuite;
  const { twitterService } = navbarService.api;
  const appState = useAppState();
  const userState = useUserState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const [shareClicked, setShareClicked] = useState(false);
  // const [loaded, setLoaded] = useState(false);
  const [loadState, setLoadState] = useState(ViewerLoadState.Idle);
  const [ref, setRef] = useState<MintVideoContentRef | null>(null);
  const [buttonLabel, setButtonLabel] = useState('SHARE TO MINT!');
  const [shareExpiration, setShareExpiration] = useState(-1);
  const [notice, setNotice] = useState<string | null>(null);
  const [twtAuthState, setTwtAuthState] = useState(TwtAuthState.Idle);

  const matchingAddr = doAddressesMatch(userState.address, mintState.hotWallet);
  const userAddress = useUserAddress();
  const hotWalletAddress = useHotWalletAddress();

  async function tweetStory() {
    if (mintState.storyKey && !mintState.storyViewerLink) {
      return;
    }
    const baseTweetCopy = mintState.sale.saleMedia?.twitterCopy?.text ?? 'Check out my new story!\n<replacemywithvideolink>\n\nFrom @storyverse_xyz';
    let tweetCopy = tokenReplace(baseTweetCopy, { replacemywithvideolink: mintState.storyViewerLink ?? '' }, true, true);
    if (mintState.storyViewerLink && !tweetCopy.includes(mintState.storyViewerLink)) {
      tweetCopy += `\n${mintState.storyViewerLink}`;
    }
    const tweetId = await twitterService.share.story({
      message: tweetCopy,
      authorAddress: mintState.storyKey.address as WalletAddress,
      storyId: mintState.storyKey.id,
    });
    log('tweeted story:', tweetId);
  }

  async function altTweetStory() {
    if (mintState.storyKey && !mintState.storyViewerLink) {
      return;
    }

    let replacemywithvideolink = mintState.storyViewerLink ?? '';
    try {
      replacemywithvideolink = await fetchViewerLink(mintState.storyKey, { lid: 'a' });
    } catch {
      console.log(`Failed to get viewer link for alternative share.`);
    }

    const baseTweetCopy = mintState.sale.saleMedia?.twitterCopy?.text ?? 'Check out my new story!\n<replacemywithvideolink>\n\nFrom @storyverse_xyz';
    let tweetCopy = tokenReplace(baseTweetCopy, { replacemywithvideolink }, true, true);
    if (mintState.storyViewerLink && !tweetCopy.includes(replacemywithvideolink)) {
      tweetCopy += `\n${replacemywithvideolink}`;
    }
    openTwitterShareIntent(tweetCopy);
  }

  async function onShare() {
    if (!mintState.alreadyMintShared && mintState.storyKey) {
      if (mintState.twitterAvailState === MintTwitterAvailState.Unavailable || appState.initialSearchParams.has(SearchParam.SkipAuth)) {
        analyticsService.track(AnalyticsEventName.ButtonPress, {
          buttonName: 'alternateShareMint',
          tokenType: mintState.sale.tokenType,
          skippedAuth: appState.initialSearchParams.has(SearchParam.SkipAuth),
        });
        altTweetStory();
        mintDispatch({
          type: MintActionType.UpdateAlreadyShared,
          shared: true,
        });
      } else {
        let loggedIn: boolean;
        if (twtAuthState !== TwtAuthState.Authorized) {
          // need to save this data for later
          savePreRedirectData({
            email: mintState.signUpEmail,
            twitter: mintState.signUpTwitter,
          });
          analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'mintTwitterAuth', tokenType: mintState.sale.tokenType });
          setShareClicked(true);
          const twtUser = await twitterService.auth.logIn({ authVersion: TwtAuthVersion.V1Write });
          loggedIn = Boolean(twtUser);
        } else {
          loggedIn = true;
        }

        if (!loggedIn) {
          return;
        }

        analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'mintShare', tokenType: mintState.sale.tokenType });
        setShareExpiration(Date.now() + secondsToMs(120));
        setNotice('Please be patient, sharing can take a minute.');
        setShareClicked(true);
        mintDispatch({
          type: MintActionType.UpdateMintedError,
          errorMsg: null,
        });

        // safe to kick off share asynchronously (purposefully not awaiting)
        tweetStory()
          .then(() => {
            analyticsService.track(AnalyticsEventName.Share, {
              type: ShareType.TWITTER,
              success: true,
            });
          })
          .catch((e) => {
            // thoughts: should we tell the user the tweeting didn't work at the end of the mint?
            analyticsService.track(AnalyticsEventName.Share, {
              type: ShareType.ERROR,
              success: false,
              message: e.message,
            });
            // just log
            log('Error occurred while tweeting story (continuing with mint):', e);
          });
        mintDispatch({
          type: MintActionType.StartMint,
          shared: true,
        });

        setShareClicked(false);
      }
    } else {
      analyticsService.track(AnalyticsEventName.ButtonPress, { buttonName: 'mint', tokenType: mintState.sale.tokenType });
      setShareClicked(true);
      mintDispatch({
        type: MintActionType.StartMint,
        shared: mintState.alreadyMintShared,
      });
    }
  }

  // detect when viewer has loaded
  useEffect(() => {
    if (!ref) {
      return;
    }
    if (ref.type !== MintVideoContentType.Viewer || mintState.alreadyMintShared) {
      // if not viewer or they already shared, we don't need to wait for load
      setLoadState(ViewerLoadState.LoadExpired);
      return;
    }

    if (loadState === ViewerLoadState.Loaded) {
      // no need to wait for load, already loaded
      return;
    }

    function onHostedAppLoaded() {
      setLoadState(ViewerLoadState.Loaded);
    }

    navbarService.once(SvsNavbarEvent.HostedAppLoaded, onHostedAppLoaded);

    return () => {
      navbarService.off(SvsNavbarEvent.HostedAppLoaded, onHostedAppLoaded);
    };
  }, [ref, mintState.alreadyMintShared, loadState]);

  // enable mint button after 15 seconds if story hasn't loaded yet
  useEffect(() => {
    if (mintState.alreadyMintShared) {
      // don't need to expire if they've already shared
      return;
    }
    if (!ref) {
      return;
    }
    if (ref.type !== MintVideoContentType.Viewer) {
      // if its not viewer, we don't need to have a load expiration
      setLoadState(ViewerLoadState.LoadExpired);
      return;
    }
    if (loadState === ViewerLoadState.LoadExpired) {
      // don't need to expire if it already expired
      return;
    }
    if (loadState === ViewerLoadState.Loaded) {
      // don't need to expire if it already loaded
      return;
    }

    const timeoutId = setTimeout(() => {
      setLoadState(ViewerLoadState.LoadExpired);
    }, secondsToMs(15));

    return () => {
      clearTimeout(timeoutId);
    };
  }, [ref, mintState.alreadyMintShared, loadState]);

  // when share times out, start the share
  useEffect(() => {
    if (shareExpiration === -1) {
      return;
    }
    if (!shareClicked) {
      return;
    }
    function triggerStartMint(shared: boolean) {
      // checking if they changed addresses mid-share
      if (!matchingAddr) {
        // do not proceed if addresses do not match
        if (shared) {
          // mark as shared so they don't have to share again
          mintDispatch({
            type: MintActionType.UpdateAlreadyShared,
            shared: true,
          });
        }
        return;
      }
      mintDispatch({
        type: MintActionType.StartMint,
        shared,
      });
    }

    const delta = shareExpiration - Date.now();
    if (delta <= 0) {
      triggerStartMint(true);
      return;
    }

    const timeoutId = setTimeout(() => triggerStartMint(true), delta);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [shareExpiration, matchingAddr, shareClicked]);

  // check twitter login state (but not verify credentials)
  useEffect(() => {
    if (twtAuthState !== TwtAuthState.Idle) {
      return;
    }

    twitterService.auth.canWrite().then((loggedIn) => {
      if (loggedIn) {
        setTwtAuthState(TwtAuthState.Authorized);
      } else {
        setTwtAuthState(TwtAuthState.Unauthorized);
      }
    });
  }, [twtAuthState]);

  // set button label based on certain states
  useEffect(() => {
    if (mintState.alreadyMintShared || !mintState.storyKey) {
      // already shared or no story is returned
      setNotice(null);
      setButtonLabel('MINT!');
      return;
    }
    if (twtAuthState === TwtAuthState.Idle || twtAuthState === TwtAuthState.Checking) {
      setNotice(null);
      setButtonLabel('SHARE TO MINT!');
      return;
    }
    if (twtAuthState === TwtAuthState.Unauthorized) {
      setNotice('Sharing will require Twitter authorization.');
      setButtonLabel('SHARE TO MINT!');
      return;
    }
    if (loadState === ViewerLoadState.LoadExpired) {
      setNotice(null);
      setButtonLabel('SHARE TO MINT!');
      return;
    }
    if (loadState === ViewerLoadState.Loaded) {
      setNotice(null);
      setButtonLabel('SHARE TO MINT!');
      return;
    }
  }, [loadState, twtAuthState, mintState.alreadyMintShared]);

  // load viewer link
  useEffect(() => {
    if (mintState.storyViewerLink) {
      return;
    }
    if (!mintState.storyKey) {
      return;
    }

    fetchViewerLink(mintState.storyKey, getLid(mintState.sale.saleType)).then((viewerLink) => {
      mintDispatch({
        type: MintActionType.UpdateStoryViewerLink,
        storyViewerLink: viewerLink,
      });
    });
  }, [mintState.storyKey, mintState.storyViewerLink, mintDispatch]);

  const loaded = loadState === ViewerLoadState.LoadExpired || loadState === ViewerLoadState.Loaded;

  return (
    <MintLayout title={'Ready to enter\nStoryverse!'} subtitle="Share your story to mint your Characters" className="mintshare-state-page">
      <MintVideoContent ref={setRef} />
      <div className="notice-con">
        {matchingAddr && !notice && !mintState.mintedError && <p className="notice">&nbsp;</p>}
        {notice && <p className="notice">{notice}</p>}
        {mintState.mintedError && <p className="error-notice">{mintState.mintedError}</p>}
        {!matchingAddr && (
          <>
            <p className="error-notice">Please connect with the same hot wallet you initially connected with.</p>
            <p className="error-notice">
              (Expected: {hotWalletAddress}, current: {userAddress})
            </p>
          </>
        )}
      </div>
      <button className="mint-btn-cta mint-share-btn" onClick={onShare} disabled={shareClicked || !loaded || !matchingAddr}>
        {buttonLabel}
      </button>
    </MintLayout>
  );
}

function MintingStatePage() {
  const { saleService, delegatecashService } = mainSuite;
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();

  // we're sending the transaction, so there's no need to check for mismatched addresses
  useEffect(() => {
    if (!mintState.mintAndSig) {
      mintDispatch({
        type: MintActionType.MintFailed,
        errorMsg: 'Missing mint data.',
      });
      return;
    }
    let stale = false;
    const nonce = delegatecashService.getNonce();
    saleService
      .mintWithSignature({
        saleId: mintState.sale.saleId,
        payload: {
          rawMessage: mintState.mintAndSig.rawMessage,
          signature: mintState.mintAndSig.signature,
          nonce,
          hotWallet: mintState.hotWallet,
          platform: mintState.platform,
        },
      })
      .then(() => {
        if (stale) {
          return;
        }

        mintDispatch({
          type: MintActionType.MintFinished,
        });
      })
      .catch((e) => {
        if (stale) {
          return;
        }

        // we got past minting, but could not get receipt
        if (e.mintingReceiptError && environment.treatReceiptErrorAsSuccess) {
          log('We could not verify the transaction has completed:', e);
          mintDispatch({
            type: MintActionType.MintFinished,
            errorMsg: environment.showMintSuccessError ? 'Transaction has started, but we could not verify the transaction has completed.' : null,
          });
          return;
        }

        // we got past minting and got the receipt,
        // we just couldn't record to our backend
        if (e.waitForMintingOfErrored && environment.treatWaitForMintAsSuccess) {
          log('Transaction has been completed, but could not record success:', e);
          mintDispatch({
            type: MintActionType.MintFinished,
            errorMsg: environment.showMintSuccessError ? 'Transaction has been completed, but an error occurred while trying to confirm the success.' : null,
          });
          return;
        }

        log('Error occurred while minting:', e);
        mintDispatch({
          type: MintActionType.MintFailed,
          errorMsg: 'Minting failed. Please try again.',
        });
        const reason = getMintErrorReason(e);
        mainSuite.analyticsService.track(AnalyticsEventName.MintError, {
          Reason: reason,
          Details: JSON.stringify({
            errorCode: e.code,
            errorMessage: e.message,
            errorStack: e.stack,
            tokenType: mintState.sale?.tokenType,
          }),
        });
      });

    return () => {
      stale = true;
    };
  }, [mintState.mintAndSig, mintState.sale]);

  return (
    <div className="minting-state-page">
      <GradientTitle>Minting&hellip;</GradientTitle>
      <TransitioningSection />
    </div>
  );
}

function MintedStatePage() {
  const mintState = useMintState();
  const { emailSubService, analyticsService } = mainSuite;

  const viewPath = useMemo(() => {
    if (!mintState.storyKey) {
      return null;
    }
    return `/view/${mintState.storyKey.address}/${mintState.storyKey.id}/ai`;
  }, [mintState.storyKey]);

  useEffect(() => {
    if (mintState.signUpEmail) {
      emailSubService
        .subscribeEmail({
          socialHandles: {
            email: mintState.signUpEmail,
          },
          isCharPass: true,
        })
        .catch(() => {
          // do nothing, just silence erro
        });
    }
  }, [mintState.signUpEmail]);

  useEffect(() => {
    analyticsService.track(AnalyticsEventName.MintSuccess, {
      added: mintState.eligibleAdded,
      count: mintState.eligibleTokens,
      hasStoryKey: !!mintState.storyKey,
      addedEmail: !!mintState.signUpEmail,
      tokenType: mintState.sale?.tokenType,
    });
  }, []);

  let nftName: string;
  if (mintState.holdings && mintState.sale?.tokenNameSingular && mintState.sale?.tokenNamePlural) {
    if (mintState.holdings.length === 1) {
      nftName = mintState.sale.tokenNameSingular;
    } else {
      nftName = mintState.sale.tokenNamePlural;
    }
  } else {
    nftName = 'NFT';
  }

  return (
    <MintLayout
      title={'Congrats! You minted\na Character Pass!'}
      subtitle={`Your ${nftName} will now be in collectible stories!`}
      className="minted-state-page"
    >
      <div className="minted-body">
        <VimeoReactPlayer url={getVimeoUrl()} autoPlayType="inview" loop className="video-300" />
        <div className="description">
          <p className="mint-layout-subtitle center">
            <b>Founder Pass</b> holders will have
          </p>
          <p className="mint-gradient-title center bottomText">
            <b>EARLY ACCESS</b> to Collectible Stories
          </p>
          <a href={mintState.sale.telegramLink} className="mint-btn-cta btn-buy-now" target="_blank">
            Join Telegram Chat
          </a>
        </div>
        {mintState.mintedError && <p className="error-notice">{mintState.mintedError}</p>}
      </div>
    </MintLayout>
  );
}

function MintNoSupplyStatePage() {
  const mintState = useMintState();
  const [showSpinner, setShowSpinner] = useState(false);
  return (
    <MintLayout title={mintState.sale.saleName} subtitle="No more supply. Sign up for future mints!" className="mintnosupply-state-page-layout">
      <MintVideoLayout videoContent={<VimeoReactPlayer url={getVimeoUrl()} autoPlayType="inview" loop />} showSpinner={showSpinner}>
        <EmailSignUpSection title="Waitlist Signup" setShowSpinner={setShowSpinner} hideCountdown />
      </MintVideoLayout>
    </MintLayout>
  );
}

function PostMintStatePage() {
  const mintState = useMintState();
  const [showSpinner, setShowSpinner] = useState(false);
  return (
    <MintLayout title={mintState.sale.saleName} subtitle="Mint period ended. Sign up for future mints!" className="postmint-state-page-layout">
      <MintVideoLayout videoContent={<VimeoReactPlayer url={getVimeoUrl()} autoPlayType="inview" loop />} showSpinner={showSpinner}>
        <EmailSignUpSection title="Waitlist Signup" setShowSpinner={setShowSpinner} hideCountdown />
      </MintVideoLayout>
    </MintLayout>
  );
}

function ErrorSection({ children }: { children?: React.ReactNode }) {
  return (
    <div className="alert alert-danger" role="alert">
      {children}
    </div>
  );
}

type StateConfig = {
  content: React.ReactNode;
  analyticsPageName: string;
  metamaskRequired: boolean;
};

const stateMap: Partial<Record<MintPageState, StateConfig>> = {
  [MintPageState.Mint]: {
    content: <MintStatePage />,
    analyticsPageName: 'mint',
    metamaskRequired: false,
  },
  [MintPageState.MintEntry]: {
    content: <MintEntryStatePage />,
    analyticsPageName: 'mintEntry',
    metamaskRequired: true,
  },
  [MintPageState.MintShare]: {
    content: <MintShareStatePage />,
    analyticsPageName: 'mintShare',
    metamaskRequired: true,
  },
  [MintPageState.Minting]: {
    content: <MintingStatePage />,
    analyticsPageName: 'minting',
    metamaskRequired: true,
  },
  [MintPageState.Minted]: {
    content: <MintedStatePage />,
    analyticsPageName: 'minted',
    metamaskRequired: true,
  },
  [MintPageState.MintNoSupply]: {
    content: <MintNoSupplyStatePage />,
    analyticsPageName: 'mintNoSupply',
    metamaskRequired: false,
  },
  [MintPageState.PostMint]: {
    content: <PostMintStatePage />,
    analyticsPageName: 'mintPost',
    metamaskRequired: false,
  },
};

export function MintSubPage() {
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const userState = useUserState();

  useEffect(() => {
    const stateConfig = stateMap[mintState.pageState] ?? null;
    if (!stateConfig) {
      return;
    }

    if (stateConfig.metamaskRequired && !userState.loggedIn) {
      mintDispatch({
        type: MintActionType.Reset,
      });
    }
  }, [mintState.pageState, userState.loggedIn]);

  const stateConfig = stateMap[mintState.pageState] ?? null;
  const content = stateConfig?.content ?? <ErrorSection>Unknown error occurred (mint).</ErrorSection>;
  const analyticsPageName = `${mintState.sale?.saleId}-${stateConfig?.analyticsPageName ?? 'mintUnknown'}`;
  return (
    <Page title="Mint" className="mint-subpage container mb-5" showFooterDiscordOnly analyticsPageName={analyticsPageName}>
      {content}
    </Page>
  );
}

export default MintSubPage;
