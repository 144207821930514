import { useEffect, useMemo, useState } from 'react';
import { Page } from '../Page';
import { mainSuite } from '@services/ServiceFactory';
import { useParams } from 'react-router-dom';
import { ViewPage } from './ViewPage';

interface StoryProps {
  walletAddress: string;
  storyId: string;
  tokenId: string;
}

export const ViewDefaultStory = () => {
  const [storyInfo, setStoryInfo] = useState<StoryProps>();

  const { storyName } = useParams();

  useEffect(() => {
    const getStoryInfo = async () => {
      const sale: any = await mainSuite.saleService.fetchSaleBy({
        defaultStoryName: storyName,
      });
      if (sale) {
        setStoryInfo({
          walletAddress: sale.nftWalletAddress,
          storyId: sale.nftStoryId,
          tokenId: sale.nftDefaultToken,
        });
      }
    };

    getStoryInfo();
  }, [storyName]);

  if (!storyInfo) {
    return <Page title="Viewer" className="view-page no-scroll" hideFooter fullHeight hideNavbar analyticsManualEntryFinal />;
  }

  return <ViewPage isNFT {...storyInfo} />;
};
