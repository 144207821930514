import { SvsProvider, TwtAuthVersion } from '@storyverseco/svs-navbar';
import { useUserHook } from '@hooks/useUserHook';
import './CollectionsForm.scss';
import './CollectionsMint.scss';
import { StepBox } from './StepBox';
import { truncateAddress } from '@common/StringUtils';
import { MintActionType, useMintDispatch, useMintState } from '@context/mint/MintContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getDatesFromSaleKind, getSaleDateFlagsFromTime } from '@common/SaleUtils';
import { SaleDateFlag } from '@common/SaleUtils';
import { debug } from '@common/LogWrapper';
import { mainSuite } from '@services/ServiceFactory';
import { useCountdownText } from '@hooks/useCountdownText';
import { TwtAuthState, twtAuthStateFinished } from '@common/TwtAuthState';
import { relativeMask } from '@common/SaleUtils';
import { saleKindMask } from '@common/SaleUtils';
import { LoginState, loginStateInProgress } from '@common/LoginState';
import { getConfig } from '@common/GetConfig';
import { ContractAddress, Wallet } from '@storyverseco/svs-types';
import { AnalyticsEventName, ShareType } from '@services/analytics/AnalyticsEventName';
import { MintBox } from '@components/MintBox/MintBox';
import { TextNewLiner } from '@components/text-new-liner/TextNewLiner';
import DiscordIcon from '@assets/landing-page-v3/logos/Discord.svg';
import TwitterIcon from '@assets/landing-page-v3/logos/Twitter.svg';
import { StepBoxTexts } from '@common/Sale';
import { useNavigate } from 'react-router-dom';

const log = debug('app:pages:CollectionsMint');
const shareOnLoadParam = 'shareOnLoad';

export type CollectionsMintProps = {};

type MessagePair = {
  message: string;
  subMessage: string | null;
};

const saleDateMsgMap: Partial<Record<SaleDateFlag, MessagePair>> = {
  [SaleDateFlag.BeforeWhitelistMint]: {
    message: '[TODO Before winners mint message]',
    subMessage: null,
  },
  [SaleDateFlag.DuringWhitelistMint]: {
    message: '[TODO During winners mint message]',
    subMessage: null,
  },
  [SaleDateFlag.AfterWhitelistMint]: {
    // only shows if it's after whitelist mint and no public mint dates
    message: '[TODO After winners mint message]',
    subMessage: 'Rawr',
  },
  [SaleDateFlag.BeforePublicMint]: {
    message: '[TODO Before public mint message]',
    subMessage: null,
  },
  [SaleDateFlag.DuringPublicMint]: {
    message: '[TODO During public mint message]',
    subMessage: null,
  },
  [SaleDateFlag.AfterPublicMint]: {
    message: '[TODO After public mint message]',
    subMessage: 'Rawr',
  },
};

export function useCountdownUntilEnd() {
  const mintState = useMintState();

  const saleDateFlags = useMemo(() => {
    if (!mintState.sale) {
      return SaleDateFlag.None;
    }

    return getSaleDateFlagsFromTime(mintState.sale);
  }, [mintState.sale]);

  const saleTime = useMemo(() => {
    if (!mintState.sale) {
      return 0;
    }
    if (!saleDateFlags) {
      return 0;
    }

    const dates = getDatesFromSaleKind(mintState.sale, saleDateFlags);
    if (!dates) {
      return 0;
    }

    if ((saleDateFlags & relativeMask) === SaleDateFlag.Before) {
      return new Date(dates.startDate).getTime();
    }

    if ((saleDateFlags & relativeMask) === SaleDateFlag.During) {
      if (!dates.endDate) {
        return 0;
      }
      return new Date(dates.endDate).getTime();
    }

    return 0;
  }, [saleDateFlags, mintState.sale]);

  const countdownText = useCountdownText(saleTime);

  let fullText: string;
  if ((saleDateFlags & relativeMask) === SaleDateFlag.Before) {
    fullText = `Mint starts in ${countdownText}`;
  } else {
    fullText = `Mint closes in ${countdownText}`;
  }

  return {
    countdownText: fullText,
    rawCountdownText: countdownText,
  };
}

export function useTwitterShare() {
  const mintState = useMintState();
  const [twtAuthState, setTwtAuthState] = useState(TwtAuthState.Idle);
  const [shareErrorMsg, setShareErrorMsg] = useState<string | null>(null);
  const { navbarService, analyticsService } = mainSuite;
  const { twitterService } = navbarService.api;

  const auth = useCallback(async () => {
    if (twtAuthState === TwtAuthState.Authorized) {
      return;
    }

    // login if not logged in (if already logged in, this will immediately return true)
    // const loggedIn = await twitterService.auth.logIn({ authVersion: TwtAuthVersion.V1Write });
    let currentURL = window.location.href;

    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has(shareOnLoadParam)) {
      // Check if the URL already has parameters
      if (currentURL.includes('?')) {
        currentURL += `&${shareOnLoadParam}=true`;
      } else {
        currentURL += `?${shareOnLoadParam}=true`;
      }
    }

    const loggedIn = await twitterService.auth.logIn({ authVersion: TwtAuthVersion.V1Write, redirectUrl: currentURL });

    if (!loggedIn) {
      return;
    }

    // verify creds before tweeting
    // we have no real way to verify bc verify only exists in 1.1, which is restricted
    // const verifyCredsResponse = await navbarService.api.twitterService.verifyCreds({ usageType: UsageType.TweetVideo });
    // if (!verifyCredsResponse.valid) {
    //   log('Twitter creds not valid');
    //   setTwtAuthState(TwtAuthState.Unauthorized);
    //   setShareErrorMsg('Twitter re-auth required');
    //   return;
    // }

    // should be authorized by this point
    setTwtAuthState(TwtAuthState.Authorized);
  }, [twtAuthState]);

  const tweetVideo = useCallback(
    async (saleKind: SaleDateFlag) => {
      // clear error message
      setShareErrorMsg(null);

      await auth();

      const cfg = await getConfig();

      // video url
      const sanitizedSaleKind = saleKind & saleKindMask;
      let videoUrl: string;
      if (sanitizedSaleKind === SaleDateFlag.WhitelistMint) {
        videoUrl = mintState.sale?.saleMedia?.shareClaimVideoUrl;
      } else if (sanitizedSaleKind === SaleDateFlag.PublicMint) {
      }

      // @TODO: We might be able to remove the if entirely
      if (!videoUrl) {
        videoUrl = `${cfg.globals.urls.media}/media/nfts/${(cfg.globals as any).playcoWalletAddress.toLowerCase()}/${mintState.sale.nftStoryId}/video.mp4`;
        // throw new Error('No viable video URL');
      }

      // tweet copy
      const baseTweetCopy = mintState.sale?.saleMedia?.twitterCopy?.text ?? 'Check out my new story!';
      // TODO any string interpolation?
      const message = baseTweetCopy;

      // tweet with video
      try {
        const tweetId = await twitterService.share.video({ message, videoUrl });
        log('tweeted video:', tweetId);
        analyticsService.track(AnalyticsEventName.Share, {
          type: ShareType.TWITTER,
          success: true,
        });
        return tweetId;
      } catch (e) {
        // treat any error as auth error
        setTwtAuthState(TwtAuthState.Unauthorized);
        log('Tweet video error:', e);
        setShareErrorMsg('Tweeting video error occurred');
        analyticsService.track(AnalyticsEventName.Share, {
          type: ShareType.ERROR,
          success: false,
          message: e.message,
        });
      }
    },
    [mintState.sale, auth],
  );

  useEffect(() => {
    if (twtAuthState !== TwtAuthState.Idle) {
      return;
    }

    setTwtAuthState(TwtAuthState.Checking);
    twitterService.auth
      .canWrite()
      .then((hasWritePerms) => {
        if (hasWritePerms) {
          setTwtAuthState(TwtAuthState.Authorized);
        } else {
          setTwtAuthState(TwtAuthState.Unauthorized);
        }
      })
      .catch((e) => {
        log('twitterService.auth.canWrite() error:', e);
        setTwtAuthState(TwtAuthState.Unauthorized);
      });
  }, [twtAuthState]);

  // show twitter auth error
  useEffect(() => {
    twitterService.auth.erroredOnRedirect().then((error) => {
      if (error) {
        setShareErrorMsg(`Error occurred during Twitter auth: ${error.message}`);
      }
    });
  }, []);

  return {
    auth,
    twtAuthState,
    tweetVideo,
    shareErrorMsg,
  };
}
interface NFTStoryUrlParts {
  viewer?: string;
  authorAddress?: string;
  storyId?: string;
  tokenId?: string;
}

function PublicMintStepBox() {
  const { sale } = useMintState();
  const [mintErrorMsg, setMintErrorMsg] = useState<string | null>(null);
  const navigate = useNavigate();

  const onMintFailure = useCallback((err: Error, { Message }) => {
    setMintErrorMsg(err.message);
  }, []);

  const onMintStart = useCallback(() => {
    // clear output message
    setMintErrorMsg(null);

    // redirect to default viewer url while mint is happening in the background
    if (sale.defaultViewerUrl) {
      try {
        const viewerUrl = new URL(sale.defaultViewerUrl);

        // we only want to route, not a full page redirect
        navigate(viewerUrl.pathname);
      } catch (e) {
        // in case it's a malformed URL, log the error but let it continue
        log(e);
      }
    } else {
      // log when default viewer url is not set
      const error = new Error(`'Error: 'defaultViewerUrl' is not set for sale '${sale.saleId}'.`);
      log(error);
    }
  }, [sale.defaultViewerUrl, sale.saleId, navigate]);

  /*
  this is superceded by navigate in onMintStart, but keeping this here
  as backup, since this will be "unlistened" anyway when navigating away, and will
  redirect if for some reason did not navigate away
  */
  const onMintSuccess = useCallback(() => {
    if (sale.defaultViewerUrl) {
      window.location.href = sale.defaultViewerUrl;
    } else {
      console.error(`Error: Could not 'defaultViewerUrl' is not set for sale '${sale.saleId}'.`);
    }
  }, []);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
        <MintBox skipAwaitToken onMintStart={onMintStart} onMintSuccess={onMintSuccess} onMintFailure={onMintFailure} />
      </div>
      {mintErrorMsg && (
        <div className="coll-form-error-msg">
          <TextNewLiner text={mintErrorMsg} />
        </div>
      )}
    </>
  );
}

const walletBtnLabelMap: Record<LoginState, { label: string; arrow: boolean }> = {
  [LoginState.Idle]: {
    label: 'CHECKING...',
    arrow: true,
  },
  [LoginState.LoggingIn]: {
    label: 'CONNECTING...',
    arrow: true,
  },
  [LoginState.LoggedIn]: {
    label: 'CONNECTED',
    arrow: false,
  },
  [LoginState.LoggingOut]: {
    label: 'DISCONNECTING...',
    arrow: true,
  },
  [LoginState.LoggedOut]: {
    label: 'CONNECT',
    arrow: true,
  },
};

function WinnersOnlyStepBox() {
  const { sale } = useMintState();
  const [mintErrorMsg, setMintErrorMsg] = useState<string | null>(null);
  const [mintSuccessMsg, setMintSuccessMsg] = useState<string | null>(null);
  const navigate = useNavigate();

  const onGoToStory = async () => {
    try {
      const params: NFTStoryUrlParts = {};

      const config = await getConfig();

      params.viewer = config.globals.urls.viewer;
      params.authorAddress = sale.nftWalletAddress;
      params.storyId = sale.nftStoryId;
      params.tokenId = await mainSuite.userService.fetchMyStoryTokenForContract({
        contractAddress: sale.tokenContractAddress.toLowerCase() as ContractAddress,
      });

      const { viewer, authorAddress, storyId, tokenId } = params;

      // @TODO: We probably want to show the error like we do for the other pages
      // although we dont expect to ever get here
      if (Object.values(params).some((v) => !v)) {
        throw new Error(`All params are required to proceed.\n${JSON.stringify(params)}`);
      }

      const url = `${viewer}/${authorAddress}/${storyId}/n/${tokenId}`;
      window.location.href = url;
    } catch (e) {
      // Fallback to default viewer
      window.location.href = sale.defaultViewerUrl;
    }
  };

  const onMintFailure = useCallback((err: Error, { Message }) => {
    setMintErrorMsg(err.message);
  }, []);

  const onMintStart = useCallback(() => {
    // clear output message
    setMintErrorMsg(null);
    setMintSuccessMsg(null);

    // redirect to default viewer url while mint is happening in the background
    if (sale.defaultViewerUrl) {
      try {
        const viewerUrl = new URL(sale.defaultViewerUrl);

        // we only want to route, not a full page redirect
        navigate(viewerUrl.pathname);
      } catch (e) {
        // in case it's a malformed URL, log the error but let it continue
        log(e);
      }
    } else {
      // log when default viewer url is not set
      const error = new Error(`'Error: 'defaultViewerUrl' is not set for sale '${sale.saleId}'.`);
      log(error);
    }
  }, [sale.defaultViewerUrl, sale.saleId, navigate]);

  /*
  this is superceded by navigate in onMintStart, but keeping this here
  as backup, since this will be "unlistened" anyway when navigating away, and will
  redirect if for some reason did not navigate away
  */
  const onMintSuccess = useCallback(() => {
    setMintSuccessMsg(`Your token(s) have been successfully added to your wallet!`);
    if (sale.defaultViewerUrl) {
      window.location.href = sale.defaultViewerUrl;
    } else {
      console.error(`Error: Could not 'defaultViewerUrl' is not set for sale '${sale.saleId}'.`);
    }
  }, []);

  const stepBoxCopy = sale?.mintCopy?.open?.winners?.stepBox;

  return (
    <>
      <StepBox
        disabled={false}
        btn={{
          show: true,
          disabled: false,
          label: stepBoxCopy?.btnLabel ?? 'Go to the story!',
          showArrow: true,
          onClick: onGoToStory,
        }}
        connected={false}
        title={stepBoxCopy?.title ?? 'Check out the story'}
        subTitle={stepBoxCopy?.subTitle ?? 'and make your choice!'}
        iconColor="#D4D2ED"
        done={false}
      />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 10 }}>
        <MintBox skipAwaitToken resetOnSuccess onMintStart={onMintStart} onMintSuccess={onMintSuccess} onMintFailure={onMintFailure} />
      </div>
      {mintErrorMsg && (
        <div className="coll-form-error-msg">
          <TextNewLiner text={mintErrorMsg} />
        </div>
      )}
      {mintSuccessMsg && <div className="coll-form-success-msg">{mintSuccessMsg}</div>}
    </>
  );
}
// Removed the need for userHook since we only care for wallet, to stop the hook from being called every frame (because of timer update)
function TwitterShareStepBox({ wallet, disabled }: { wallet: Wallet; disabled: boolean }) {
  const { tweetVideo, shareErrorMsg, auth } = useTwitterShare();
  const [isUnlocking, setIsUnlocking] = useState(false);
  const { sale } = useMintState();
  const mintDispatch = useMintDispatch();
  // const { countdownText } = useCountdownUntilEnd();

  async function shareToUnlock() {
    if (isUnlocking) {
      return;
    }

    if (!wallet?.address) {
      return;
    }

    const { saleService } = mainSuite;

    mainSuite.analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'collection_share_to_unlock',
    });

    setIsUnlocking(true);
    try {
      try {
        await auth();
        mainSuite.analyticsService.track(AnalyticsEventName.TwitterAuth, {
          success: true,
        });
      } catch (e) {
        mainSuite.analyticsService.track(AnalyticsEventName.TwitterAuth, {
          success: false,
          reason: e.message,
        });
        throw e;
      }

      // This will trigger the async video tweet
      tweetVideo(SaleDateFlag.WhitelistMint);

      const response = await saleService.contestStatus({
        saleId: sale.saleId,
        walletAddress: wallet.address,
      });

      if (!response.winner) {
        const saleData = await saleService.fetchSale(sale.saleId);
        try {
          const myTokenId = await mainSuite.userService.fetchMyStoryTokenForContract({
            contractAddress: saleData.tokenContractAddress as ContractAddress,
          });

          mainSuite.analyticsService.track(AnalyticsEventName.ContestStatus, {
            ...response,
            hasMinted: Boolean(myTokenId),
          });
        } catch {
          mainSuite.analyticsService.track(AnalyticsEventName.ContestStatus, {
            ...response,
            hasMinted: false,
          });
        }
        // Go to loser page
        mintDispatch({
          type: MintActionType.UpdateWinner,
          winner: false,
        });
        return;
      }

      mainSuite.analyticsService.track(AnalyticsEventName.ContestStatus, response);

      if (!response.claimed) {
        mainSuite.analyticsService.track(AnalyticsEventName.ContestStatusClaim, response);
        await saleService.claim({
          saleId: sale.saleId,
          claimPayload: {
            walletAddress: wallet.address,
          },
        });
      }
      // Go to winner page
      mintDispatch({
        type: MintActionType.UpdateWinner,
        winner: true,
      });
    } catch (e) {
      log('Mint error:', e);
    } finally {
      setIsUnlocking(false);
    }
  }

  const stepBoxTexts: StepBoxTexts = {
    title: sale?.saleMedia?.twitterButtonTitle ?? 'Share your story!',
    subTitle: '',
    btnLabel: 'CHECK IF YOU WON!',
    ...sale?.mintCopy?.open?.shareStepBox,
  };

  useEffect(
    () => {
      if (!wallet) {
        return;
      }

      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has(shareOnLoadParam)) {
        shareToUnlock();
      }
    },
    // needs to pass address as dependency as first render happens without a wallet
    [wallet?.address],
  );

  return (
    <>
      <StepBox
        disabled={disabled}
        btn={{
          show: true,
          disabled: isUnlocking || disabled,
          label: stepBoxTexts.btnLabel,
          showArrow: true,
          onClick: shareToUnlock,
        }}
        connected={false}
        title={stepBoxTexts.title}
        subTitle={stepBoxTexts.subTitle}
        icon="twitter"
        iconColor="#D4D2ED"
        done={false}
      />
      {shareErrorMsg && <div className="coll-form-error-msg">{shareErrorMsg}</div>}
    </>
  );
}

function CollectionMintClosed() {
  const { sale } = useMintState();

  const goToOpensea = () => {
    window.open(sale.mintedOpenseaLink, '_blank');
  };

  const title = sale?.mintCopy?.closed?.contentTitle ?? 'Sorry!';
  const subTitle = sale?.mintCopy?.closed?.contentSubTitle ?? `${sale.saleName} open mint is closed`;
  const stepBox: StepBoxTexts = {
    title: 'Get yourself a story',
    subTitle: 'and make a choice!',
    btnLabel: 'Buy on Opensea',
    ...sale?.mintCopy?.closed?.stepBox,
  };

  return (
    <>
      <h1 style={{ textAlign: 'left' }}>{title}</h1>
      <h2 style={{ textAlign: 'left' }}>{subTitle}</h2>
      <br />
      <StepBox
        disabled={false}
        btn={{
          show: true,
          disabled: false,
          label: stepBox.btnLabel,
          showArrow: true,
          onClick: goToOpensea,
        }}
        connected={false}
        title={stepBox.title}
        subTitle={stepBox.subTitle}
        iconColor="#D4D2ED"
        done={false}
      />
    </>
  );
}

function CollectionMintOpeningSoon() {
  const mintState = useMintState();

  return (
    <>
      <h1 style={{ textAlign: 'left' }}>{mintState.sale?.mintCopy?.upcoming?.contentTitle ?? 'Sorry, registration is closed.'}</h1>
      <br />
      <h3 style={{ textAlign: 'left' }}>{mintState.sale?.mintCopy?.upcoming?.contentSubTitle ?? 'The mint will go live soon!'}</h3>
      <br />
      <h3 style={{ textAlign: 'left' }}>Follow us on Twitter and join our Discord to get notified the moment the mint goes live!</h3>
      <br />
      <a href="https://discord.com/invite/storyverse" className="linkWithIcon">
        <img src={DiscordIcon} />
        <p>Join our discord</p>
      </a>
      <a href="https://twitter.com/storyverse_xyz" className="linkWithIcon">
        <img src={TwitterIcon} />
        <p>Follow @storyverse_xyz</p>
      </a>
    </>
  );
}

export function CollectionsMint({}: CollectionsMintProps) {
  // const appState = useAppState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const { wallet, logIn, ready, loginState } = useUserHook({ providerType: SvsProvider.WalletConnect });

  const isConnected = ready && Boolean(wallet?.flags.loggedIn);
  const address = isConnected ? wallet?.address : null;

  // Store whether we have tried refreshing or not (once)
  // const [firstLoginRefresh, setFirstLoginRefresh] = useState(true);

  const saleDateFlags = useMemo(() => {
    if (!mintState.sale) {
      return SaleDateFlag.None;
    }

    return getSaleDateFlagsFromTime(mintState.sale);
  }, [mintState.sale]);

  const showPreMint = saleDateFlags === SaleDateFlag.BeforePublicMint;

  const showMintClosed = !showPreMint && saleDateFlags !== SaleDateFlag.DuringPublicMint;

  const winnerOnly = Boolean((saleDateFlags & saleKindMask) === SaleDateFlag.WhitelistMint);

  const walletBtnConfig = walletBtnLabelMap[loginState];

  const walletBoxTitle = address ? truncateAddress(address) : 'Connect your wallet';
  const walletBoxSubtitle = isConnected ? 'Wallet Connected' : winnerOnly ? 'Claim your prize!' : 'Mint a story!';

  return (
    <div className="coll-form">
      <div className="coll-form-container">
        {showPreMint && <CollectionMintOpeningSoon />}
        {showMintClosed && <CollectionMintClosed />}
        {!showMintClosed && !showPreMint && (
          <>
            {mintState.winner === null && (
              <StepBox
                disabled={false}
                btn={{
                  show: true,
                  disabled: isConnected || loginStateInProgress(loginState),
                  label: walletBtnConfig.label,
                  showArrow: walletBtnConfig.arrow,
                  onClick: () => {
                    mainSuite.analyticsService.track(AnalyticsEventName.ButtonPress, {
                      buttonName: 'mint-walletConnect',
                      saleId: mintState.sale?.saleId,
                    });
                    logIn();
                  },
                }}
                connected={isConnected}
                title={walletBoxTitle}
                subTitle={''}
                icon="wallet"
                iconColor="#D4D2ED"
                done={isConnected}
              />
            )}
            {mintState.winner === null && <TwitterShareStepBox wallet={wallet} disabled={!isConnected} />}
            {mintState.winner === false && <PublicMintStepBox />}
            {mintState.winner && <WinnersOnlyStepBox />}
          </>
        )}
      </div>
    </div>
  );
}

export default CollectionsMint;
