import React, { useEffect } from 'react';
import { debug } from 'debug';
import { useLocation, useNavigate } from 'react-router';
import { useUserState } from '@services/user/UserContext';
import { UserPermission } from '@common/UserPermission';
import { environment } from '@environment';
import { Alert } from '@components/alerts/Alert';
import './Page.scss';
import { mainSuite } from '@services/ServiceFactory';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { AppActionType, useAppDispatch } from '@context/AppContext';
import { useNavbarPageChanger } from '@hooks/useNavbarPageChanger';
import { useMintState } from '@context/mint/MintContext';

const log = debug('app:pages:Page');

export type PageProps = {
  title?: string;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  restricted?: UserPermission | UserPermission[];
  introBg?: boolean;
  analyticsPageName?: string;
  analyticsManualEntryFinal?: boolean;
  hideFooter?: boolean;
  showFooterMintDates?: boolean;
  showFooterDiscordOnly?: boolean;
  fullHeight?: boolean;
  hideNavbar?: boolean;
  fixedNavbar?: boolean;
  showWalletButton?: boolean;
};

function createIsInArray<T>(array: T[]): (value: T) => boolean {
  return (value: T) => array.indexOf(value) !== -1;
}

function hasAllPermissions(userPerms: UserPermission[], requiredPerms: UserPermission[]) {
  return requiredPerms.every(createIsInArray(userPerms));
}

function BasePage({ className, children, style }: PageProps) {
  return (
    <div className={`page ${className ?? ''}`} style={style}>
      {children}
    </div>
  );
}

function RestrictedPage(props: PageProps) {
  const userState = useUserState();
  const navigate = useNavigate();
  const location = useLocation();
  const permissions = Array.isArray(props.restricted) ? props.restricted : [props.restricted];

  useEffect(() => {
    if (!userState.loggedIn) {
      navigate('/login?r=' + encodeURIComponent(location.pathname));
    }
  }, [userState]);

  if (!userState.loggedIn) {
    return null;
  }

  if (!hasAllPermissions(userState.permissions, permissions)) {
    return (
      <BasePage title="401" className="unauthorized container py-2">
        <Alert.Danger>Unauthorized.</Alert.Danger>
      </BasePage>
    );
  }
  return <BasePage {...props} />;
}

export function Page(props: PageProps): JSX.Element {
  const appDispatch = useAppDispatch();
  const mintState = useMintState();

  useNavbarPageChanger();

  useEffect(() => {
    if (props.title) {
      document.title = `${props.title} - ${environment.pageTitle}`;
    } else {
      document.title = environment.pageTitle;
    }
  }, [props.title]);

  useEffect(() => {
    if (props.introBg) {
      document.body.classList.add('intro');
    } else {
      document.body.classList.remove('intro');
    }
  }, [props.introBg]);

  useEffect(() => {
    if (!props.analyticsPageName) {
      return;
    }

    /*
    we're keeping it here so we can get the tokenType set asap, since setting persisted event options in analytics
    from the mint pages can be set after we have already sent the PageView event.
    */
    const tokenType = mintState.sale?.tokenType;
    mainSuite.analyticsService.track(AnalyticsEventName.PageView, { pageName: props.analyticsPageName, tokenType });
    mainSuite.analyticsService.userTrack({
      pageName: props.analyticsPageName,
      tokenType,
    });
  }, [props.analyticsPageName, mintState.sale]);

  useEffect(() => {
    if (props.analyticsManualEntryFinal) {
      return;
    }
    mainSuite.analyticsService.track(AnalyticsEventName.EntryFinal);
  }, [props.analyticsManualEntryFinal]);

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateFooter,
      showFooter: !props.hideFooter,
    });
  }, [props.hideFooter]);

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateFooter,
      showFooterMintDates: props.showFooterMintDates ?? false,
    });
  }, [props.showFooterMintDates]);

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateFooter,
      showFooterDiscordOnly: props.showFooterDiscordOnly ?? false,
    });
  }, [props.showFooterDiscordOnly]);

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateMainCon,
      fullHeight: props.fullHeight ?? false,
    });
  }, [props.fullHeight]);

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateWalletButton,
      showWalletButton: props.showWalletButton ?? false,
    });
  }, [props.showWalletButton]);

  useEffect(() => {
    appDispatch({
      type: AppActionType.UpdateNavbar,
      showNavbar: !props.hideNavbar,
      fixedNavbar: props.fixedNavbar,
      showFooter: !props.hideFooter,
    });
  }, [props.hideNavbar, props.fixedNavbar]);

  if (props.restricted) {
    return <RestrictedPage {...props} />;
  }

  return <BasePage {...props} />;
}
