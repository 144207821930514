import { debug } from './LogWrapper';
import { ContentType } from './ContentType';
import { environment } from '@environment';
import { tokenReplace } from './StringUtils';
import { RestApiError, SvsAuthError } from '@errors';
import { waitForExpectedValue, WaitForExpectedValueOptions } from './WaitUtils';

const log = debug('app:common:SvsRestApi');

export enum AuthEndpoint {
  Authorize = '/twitter/authorize/{apiVersion}',
  Refresh = '/twitter/refresh',
  Verify = '/twitter/verify/{apiVersion}',
}

export enum MediaServiceEndpoint {
  FollowVerify = '/verify',
  UploadVideo = '/upload',
  Tweet = '/tweet',
}

export enum PipelineEndpoint {
  AddToPending = '/tw/authV2/addToPending',
  Pending = '/tw/authV2/pending/{pendingKey}',
  Chainalysis = '/chainalysis/{walletAddress}',
  Tokenproof = '/tp/{nonce}',
  WlAdd = '/wl/{tokenType}/waitlist',
  WlSpotsRemaining = '/wl/{tokenType}/spots/remaining',
  SignForMint = '/mint/{tokenType}/signForMint/{premint}',
  StoryNFTSignForMint = '/mint/storyNFT/{tokenType}/0/signForMint/',
  WaitForMintingOf = '/mint/{tokenType}/waitForMintingOf',
  TokenThumbnails = '/mint/{tokenType}/tokenThumbnails',
  TweetStory = '/twitter/tweetStory',
  TweetVideo = '/twitter/tweetVideo',
  Preshare = '/preshare/{walletAddress}/{storyId}',
  SetClaimed = '/mint/contestStatus/{contractKey}/{walletAddress}?claim=1',
  oneoffAirDrop = '/mint/{tokenType}/oneoffAirDrop', // TODO need actual endpoint
  GetContestStatus = '/mint/contestStatus/{contractKey}/{walletAddress}',
  GetMintFee = '/mint/ethPerUsd',
  Delegatecash = '/mint/dc/{tokenType}/{walletAddress}',
  ConfirmMintInitiated = '/mint/{tokenType}/confirmMintInitiated',
}

export enum MbaasEndpoint {
  Mint = '/api/v0/chains/ethereum/addresses/{addressLabel}/contracts/{sharedLabel}/methods/signatureMint',
}

export type AuthTokens = {
  apiVersion?: string;
};

export type MediaServiceTokens = {};

export type PipelineTokens = {
  pendingKey?: string;
  walletAddress?: string;
  nonce?: string;
  policyId?: string;
  tokenType?: string;
  premint?: string;
  storyId?: string;
  saleId?: string;
  contractKey?: string;
};

export type MbaasTokens = {
  addressLabel?: string;
  sharedLabel?: string;
};

export function getAuthEndpoint(endpoint: AuthEndpoint | string, tokens: AuthTokens = {}) {
  return `${environment.authBaseUrl}${tokenReplace(endpoint, tokens, true)}`;
}

export function getMediaServiceEndpoint(endpoint: MediaServiceEndpoint | string, tokens: MediaServiceTokens = {}) {
  return `${environment.mediaServiceBaseUrl}${tokenReplace(endpoint, tokens, true)}`;
}

export function getPipelineEndpoint(endpoint: PipelineEndpoint | string, tokens: PipelineTokens = {}): string {
  return `${environment.pipelineBaseUrl}${tokenReplace(endpoint, tokens, true)}`;
}

export function getMbaasEndpoint(endpoint: MbaasEndpoint | string, tokens: MbaasTokens): string {
  return `${environment.mbaasBaseUrl}${tokenReplace(endpoint, tokens, true)}`;
}

export async function apiCall<TBody = any>(opts: {
  method: string;
  url: string;
  body?: TBody;
  requestInit?: RequestInit;
  retryOpts?: WaitForExpectedValueOptions<Response>;
}): Promise<Response> {
  const { headers, ...restInit } = opts.requestInit ?? {};
  const maybeHeaders: Record<string, string> = {};
  if (opts.body && (opts.method.toUpperCase() === 'POST' || opts.method.toUpperCase() === 'PUT')) {
    maybeHeaders['Content-Type'] = ContentType.Json;
  }
  const requestInit: RequestInit = {
    method: opts.method,
    headers: {
      ...maybeHeaders,
      ...headers,
    },
    cache: 'no-cache',
    ...restInit,
  };
  if (opts.body) {
    requestInit.body = JSON.stringify(opts.body);
  }

  if (opts.retryOpts) {
    const { promise } = waitForExpectedValue(async () => {
      const response = await fetch(opts.url, requestInit);
      if (!response.ok) {
        const text = await response.text();
        throw new Error(`Error (${response.status}) response: ${text}`);
      }
      return response;
    }, opts.retryOpts);

    return await promise;
  }

  const response = await fetch(opts.url, requestInit);

  return response;
}

export async function formatResponse<TResponse = any>(response: Response): Promise<TResponse> {
  const headers: Record<string, string> = {};
  response.headers?.forEach((value, key) => {
    headers[key.toLowerCase()] = value;
  });
  const responseContentType = headers['content-type']?.trim() ?? ContentType.Json;
  const str = await response.text();
  if (responseContentType.includes(ContentType.Json)) {
    try {
      return JSON.parse(str);
    } catch (e) {
      log('Invalid JSON format, using text instead:', e.message);
      return str as unknown as TResponse;
    }
  }
  if (responseContentType.includes(ContentType.Form)) {
    return new URLSearchParams(str).toString() as unknown as TResponse;
  }
  // html, text, default
  return str as unknown as TResponse;
}

// defensive: splitting to avoid compiler from treating it as an actual tag
const preStartTag = '<pr' + 'e>';
const preEndTag = '</pr' + 'e>';

function formatPipelineErrorResponse(message: string): string {
  const preStartTagIndex = message.indexOf(preStartTag);
  const preEndTagIndex = message.indexOf(preEndTag);

  // if one or both don't exist, just return message as is
  if (preStartTagIndex === -1 || preEndTagIndex === -1) {
    return message;
  }

  return message.substring(preStartTagIndex + preStartTag.length, preEndTagIndex);
}

export async function authApiCall<TBody = any, TResponse = any>({
  method,
  endpoint,
  body,
  tokens = {},
  requestInit,
  retryOpts,
}: {
  method: string;
  endpoint: AuthEndpoint | string;
  body?: TBody;
  tokens?: AuthTokens;
  requestInit?: RequestInit;
  retryOpts?: WaitForExpectedValueOptions<Response>;
}): Promise<TResponse> {
  const url = getAuthEndpoint(endpoint, tokens);
  const response = await apiCall({
    method,
    url,
    body,
    requestInit,
    retryOpts,
  });
  if (!response.ok) {
    const errorStr = await response.text();
    log(`Auth endpoint error (status: ${response.status}): ${errorStr}`);
    throw new RestApiError(response.status, `Error occurred while authorizing (status: ${response.status})`, errorStr);
  }
  return await formatResponse(response);
}

export async function mediaServiceApiCall<TBody = any, TResponse = any>({
  method,
  endpoint,
  body,
  tokens = {},
  requestInit,
  retryOpts,
}: {
  method: string;
  endpoint: MediaServiceEndpoint | string;
  body?: TBody;
  tokens?: MediaServiceTokens;
  requestInit?: RequestInit;
  retryOpts?: WaitForExpectedValueOptions<Response>;
}): Promise<TResponse> {
  const url = getMediaServiceEndpoint(endpoint, tokens);
  const response = await apiCall({
    method,
    url,
    body,
    requestInit,
    retryOpts,
  });
  if (!response.ok) {
    let authError = false;
    const errorStr = await response.text();

    // older response type for auth error
    if (response.status === 400) {
      try {
        const errorData = JSON.parse(errorStr);
        if (errorData.message === 'Auth error') {
          authError = true;
        }
      } catch (e) {
        // do nothing, just do normal RestApierror
      }
    }

    // newer response type for auth error
    if (response.status === 401 || response.status === 403) {
      authError = true;
    }
    if (authError) {
      throw new SvsAuthError(`Auth error (status: ${response.status})`);
    }

    log(`Media service error (status: ${response.status}):`, errorStr);
    throw new RestApiError(response.status, `Media service error (status: ${response.status})`, errorStr);
  }
  return await formatResponse(response);
}

function withQueryParams(url: string, qp: Record<string, string>) {
  let qpStr = '';
  Object.keys(qp).forEach((p, i) => {
    qpStr += url.includes('?') ? '&' : '?';
    qpStr += `${p}=${qp[p]}`;
  });
  return `${url}${qpStr}`;
}

export async function pipelineApiCall<TBody = any, TResponse = any>({
  method,
  endpoint,
  tokens = {},
  body,
  requestInit,
  retryOpts,
  queryParams = {},
}: {
  method: string;
  endpoint: PipelineEndpoint | string;
  tokens?: PipelineTokens;
  body?: TBody;
  requestInit?: RequestInit;
  retryOpts?: WaitForExpectedValueOptions<Response>;
  queryParams?: Record<string, string>;
}): Promise<TResponse> {
  const url = withQueryParams(getPipelineEndpoint(endpoint, tokens), queryParams);
  const response = await apiCall({
    url,
    method,
    body,
    requestInit,
    retryOpts,
  });
  if (!response.ok) {
    const errorStr = formatPipelineErrorResponse(await response.text());
    log(`Pipeline error (status: ${response.status}, method: ${method}, endpoint: ${endpoint}): ${errorStr}`);
    throw new RestApiError(response.status, `Pipeline error (status: ${response.status})`, errorStr);
  }

  return await formatResponse(response);
}

export async function mbaasApiCall<TBody = any, TResponse = any>({
  method,
  endpoint,
  tokens = {},
  body,
  requestInit,
  retryOpts,
}: {
  method: string;
  endpoint: MbaasEndpoint | string;
  tokens?: MbaasTokens;
  body?: TBody;
  requestInit?: RequestInit;
  retryOpts?: WaitForExpectedValueOptions<Response>;
}): Promise<TResponse> {
  const { headers, ...restInit } = requestInit ?? {};
  const combinedInit: RequestInit = {
    headers: {
      Authorization: `Bearer ${environment.mbaasApiKey}`,
      ...headers,
    },
    credentials: 'include',
    ...restInit,
  };
  const url = getMbaasEndpoint(endpoint, tokens);
  const response = await apiCall({
    url,
    method,
    body,
    requestInit: combinedInit,
    retryOpts,
  });
  if (!response.ok) {
    const errorData = await response.json();
    log(`Mbaas error (status: ${response.status}): ${errorData.message}`);
    throw new RestApiError(response.status, `Mbaas error (status: ${response.status}): ${errorData.message}`);
  }
  return await formatResponse(response);
}
