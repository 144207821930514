export enum MintActionType {
  UpdateLoadState = 'mint/updateLoadState',
  UpdateMintPageState = 'mint/updateMintPageState',
  UpdateSale = 'mint/updateSale',
  UpdateCheckType = 'mint/updateCheckType',
  UpdateWalletToCheck = 'mint/updateWalletToCheck',
  StartCheck = 'mint/startCheck',
  CheckFailed = 'mint/checkFailed',
  UpdateEligibility = 'mint/updateEligibility',
  UpdateColdWallet = 'mint/updateColdWallet',
  UpdateHotWallet = 'mint/updateHotWallet',
  StartMintEntry = 'mint/startMintEntry',
  UpdateMintAndSig = 'mint/updateMintAndSig',
  UpdateMintedNfts = 'mint/updateMintedNfts',
  UpdateAlreadyShared = 'mint/updateAlreadyShared',
  UpdateMintedError = 'mint/updateMintedError',
  StartMintShare = 'mint/startMintShare',
  StartMint = 'mint/startMint',
  MintFinished = 'mint/mintFinished',
  MintFailed = 'mint/mintFailed',
  UpdateMintTitles = 'mint/updateMintTitles',
  UpdateWinner = 'mint/updateWinner',
  UpdateTwitterAvailState = 'mint/updateTwitterAvailState',
  UpdateStoryViewerLink = 'mint/updateStoryViewerLink',
  Reset = 'mint/reset',
}
