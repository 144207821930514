import { environment } from '@environment';
import { MintActionType, MintPageState, useMintDispatch, useMintState } from '@context/mint/MintContext';
import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { mainSuite } from '@services/ServiceFactory';
import { useUserState } from '@services/user/UserContext';
import { DcAccount, DcStatus } from '@services/delegatecash/DelegatecashService';
import { NeedAccountModal } from '../components/NeedAccountModal';
import { LoginState, loginStateInProgress } from '@common/LoginState';
import { Link, useLocation } from 'react-router-dom';
import { SvsNavbarEvent, SvsProvider, TwtAuthVersion } from '@storyverseco/svs-navbar';
import { AnalyticsEventName, ShareType } from '@services/analytics/AnalyticsEventName';
import { debug } from '@common/LogWrapper';
import { tokenReplace, truncateAddress } from '@common/StringUtils';
import { createMetaMaskDeeplink } from '@common/ProviderUtils';
import { useUserHook } from '@hooks/useUserHook';
import { StepBox } from '../components/StepBox';

const log = debug('app:pages:MintSubPage:CharPassAuthSection:RestDelegatecashService');

export enum LoginStep {
  Idle,
  LoginWithMetamask,
  LoginWithDelegateCash,
  Done,
  NoMetamask,
}

function useUserAddress(truncated = true): string | null {
  const userState = useUserState();
  return useMemo(() => {
    if (!userState.loggedIn) {
      return null;
    }
    if (!userState.address) {
      return null;
    }

    return truncated ? truncateAddress(userState.address) : userState.address;
  }, [userState.address, userState.loggedIn, truncated]);
}

function NoMetamaskButton() {
  const location = useLocation();

  return (
    <a className="svs-big-btn metamask-btn" href={createMetaMaskDeeplink(location.pathname)} target="_blank">
      <span className="mobile">Download MetaMask</span>
      <span className="desktop">Install MetaMask</span>
    </a>
  );
}

export function LoginButton({ showSpinner }: { showSpinner: (show: boolean) => void }) {
  const { delegatecashService, navbarService, chainalysisService } = mainSuite;
  const { logIn: mmLogIn, wallet: mmWallet, loginState: mmLoginState, ready: mmReady, userState } = useUserHook({ providerType: SvsProvider.WalletConnect });
  const isMMConnected = mmReady && Boolean(mmWallet?.flags.loggedIn);
  const mintDispatch = useMintDispatch();
  const mintState = useMintState();
  const [currentStep, setCurrentStep] = useState(LoginStep.Idle);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [needAccountModal, setNeedAccountModal] = useState(false);
  const [checkingMetamask, setCheckingMetamask] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const userAddress = useUserAddress();
  const showAddress = Boolean(currentStep === LoginStep.LoginWithMetamask && userAddress);

  const walletBoxTitle = userAddress ?? 'Connect your wallet';
  const walletBoxSubtitle = isMMConnected ? 'Wallet Connected' : 'Verify your character ownership';

  function nextLoginStep() {
    const step = currentStep + 1;
    if (step < LoginStep.Done) {
      setCurrentStep(step);
    } else {
      // startMintEntry();
      //  onLoginComplete();
    }
  }

  async function onDcLogin() {
    // I assume we should always login with delegatecash?
    //if (delegatecashService.isLoggedIn()) {
    //nextLoginStep();
    //return;
    //}

    showSpinner(true);
    let account: DcAccount = undefined;
    try {
      account = await delegatecashService.loginAndSign({
        mintPageState: mintState.pageState,
        tokenType: mintState.sale?.tokenType,
        walletAddress: userState.address,
        force: true,
      });
    } catch (e) {
      log('Error occurred while logging in with delegatecash', e);
      const message = e.error || e.message || 'Error occurred while authenticating';
      setErrorMessage(message);
    } finally {
      showSpinner(false);
    }
    if (!account) {
      // todo: figure out here. Idk what backend return
      const status = delegatecashService.getStatus();
      if (status === DcStatus.Closed) {
        log('Cancelled tokenproof login');
        setNeedAccountModal(true);
      } else if (status === DcStatus.Rejected) {
        log('Rejected by delegatecash');
        setNeedAccountModal(true);
      } else if (status === DcStatus.Unknown) {
        log('Unknown delegatecash status');
      }
      return;
    }
    nextLoginStep();
    // onLoginComplete();
  }

  async function onMetaMaskLogin() {
    if (mmWallet?.flags.loggedIn) {
      nextLoginStep();
      return;
    }

    mmLogIn();
    showSpinner(true);
    setCheckingMetamask(true);
  }

  // metamask state changes
  useEffect(() => {
    if (currentStep !== LoginStep.LoginWithMetamask) {
      return;
    }

    const loginInProgress = loginStateInProgress(mmLoginState);

    if (!isLoggingIn && loginInProgress) {
      setIsLoggingIn(true);
      return;
    }

    if (mmWallet?.flags.loggedIn) {
      nextLoginStep();
    }

    // only check with falling edge for "login" state
    if (checkingMetamask && !loginInProgress && isLoggingIn) {
      setCheckingMetamask(false);
      showSpinner(false);
      setIsLoggingIn(false);
      return;
    }
  }, [checkingMetamask, currentStep, mmLoginState, mmWallet, isLoggingIn]);

  // check if user logged out of metamask during delegate cash step
  useEffect(() => {
    if (currentStep !== LoginStep.LoginWithDelegateCash) {
      return;
    }

    if (mmWallet?.flags.loggedIn) {
      return;
    }

    // go back to metamask
    setCurrentStep(LoginStep.LoginWithMetamask);
  }, [currentStep, mmWallet?.flags.loggedIn]);

  useEffect(() => {
    if (currentStep !== LoginStep.Idle) {
      return;
    }

    showSpinner(true);

    navbarService
      .isWeb3Capable()
      .then((capable) => {
        nextLoginStep();
      })
      .catch((e) => {
        log('isWeb3Capable error:', e);
      });

    return () => {
      showSpinner(false);
    };
  }, [currentStep, nextLoginStep]);

  // todo: loading label
  return (
    <div className="mint-login-btn-con">
      {/* LoginStep.LoginWithMetamask: step 1: before user connect his metamask wallet */}
      {currentStep === LoginStep.LoginWithMetamask && (
        <StepBox
          disabled={false}
          btn={{
            show: true,
            disabled: isMMConnected,
            label: isMMConnected ? 'CONNECTED' : 'CONNECT',
            showArrow: !isMMConnected,
            onClick: () => {
              onMetaMaskLogin();
            },
          }}
          connected={isMMConnected}
          title={walletBoxTitle}
          subTitle={walletBoxSubtitle}
          icon="wallet"
          iconColor="#D4D2ED"
          done={isMMConnected}
        />
      )}
      {/* LoginStep.LoginWithDelegateCash: step 2: user connect his delegatecash wallet */}
      {currentStep === LoginStep.LoginWithDelegateCash && (
        <StepBox
          disabled={false}
          btn={{
            show: true,
            disabled: delegatecashService.isLoggedIn(),
            label: delegatecashService.isLoggedIn() ? 'Authenticated' : 'Authenticate with delegate.cash',
            showArrow: !isMMConnected,
            buttonIcon: 'https://delegate.cash/images/delegatecash-logo.png',
            onClick: () => {
              onDcLogin();
            },
          }}
          connected={isMMConnected}
          title={walletBoxTitle}
          subTitle={walletBoxSubtitle}
          icon="wallet"
          iconColor="#D4D2ED"
          done={isMMConnected}
        />
      )}
      {!errorMessage && (
        <p className="notice">
          {currentStep === LoginStep.LoginWithMetamask && 'Connect with your hot wallet; This is the wallet that will mint.'}
          {currentStep === LoginStep.LoginWithDelegateCash && 'Authenticate with your cold wallet; This is the wallet that has your NFTs.'}
        </p>
      )}
      {errorMessage && <p className="notice error-message">{errorMessage}</p>}
    </div>
  );

  /*
  return (
    <div className="mint-login-btn-con">
      {currentStep === LoginStep.Idle && (
        <button className="svs-big-btn metamask-btn" disabled>
          Loading...
        </button>
      )}
      {currentStep === LoginStep.LoginWithDelegateCash && <DcButton overrideOnClick={onDcLogin} />}
      {currentStep === LoginStep.LoginWithMetamask && (
        <button className="svs-big-btn metamask-btn" onClick={onMetaMaskLogin}>
          Connect Wallet
        </button>
      )}
      {currentStep === LoginStep.NoMetamask && <NoMetamaskButton />}
      <p className="notice">
        {currentStep === LoginStep.LoginWithDelegateCash && 'Authenticate with your cold wallet; This is the wallet that has your NFTs.'}
        {currentStep === LoginStep.LoginWithMetamask && 'Connect with your hot wallet; This is the wallet that will mint.'}
      </p>
      {currentStep === LoginStep.NoMetamask && (
        <>
          <p className="notice mobile">Mobile Users: Use MetaMask browser to continue</p>
          <p className="notice desktop">Desktop Users: Install MetaMask browser extension to continue</p>
        </>
      )}
      {showAddress && <p className="notice">Currently connected with {userAddress}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {needAccountModal && <NeedAccountModal onClose={() => setNeedAccountModal(false)} />}
    </div>
  );*/
}
