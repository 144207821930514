export const environment = {
  production: false,
  envName: 'demo',
  routerBasename: null,
  pageTitle: 'Storyverse (Demo)',
  serviceAddress: '',
  userServiceApi: 'metamask',
  adminApi: 'mock',
  metadataApi: 'rest',
  plotsApi: 'mbaas',
  analyticsApi: 'amp',
  chainalysisApi: 'rest',
  navbarApi: 'navbar',
  walletApi: 'navbar',
  writerApi: 'navbar',
  saleApi: 'rest',
  tokenproofApi: 'rest',
  emailSubApi: 'rest',
  twitterApi: 'rest',
  ampKey: 'a05393db4e1a3acb07dadf676ea6cbb4',
  ampProxy: 'proxy.beta.pnk.one',
  ampGameId: '8c5f325d8d2b88257f6e6c6a15aca207',
  restMetadataUrl: 'https://meta.alpha.pnk.one/a',
  openSeaUrl: 'https://testnets.opensea.io',
  openSeaContract: '0x17551c9e53a1d7345ffa7d6ed00040232cbf6858',
  introUrl: 'https://intro.storyverse.pnk.one',
  chainalysisUrl: 'https://api.chainalysis.com/api/kyt/v1/',
  chainalysisApiKey: 'e9a2a04ab126e27b40d67809fc8f3f4245cbf8b4934ec15a12933bd203a910be',
  mockLoadingMs: 500,
  forcePageState: null,
  allowForcePage: true,
  mapsUrl: 'https://maps.alpha.pnk.one',
  createUrl: 'https://create.beta.pnk.one/index.html',
  viewUrl: 'https://view.beta.pnk.one/index.html',
  ethNetwork: 'rinkeby',
  serviceProxyUrl: 'https://blockchain.beta.pnk.one',
  plotsBaseURI: 'https://meta.alpha.pnk.one/a',
  persistStoryServerPrefix: 'https://pipeline.beta.pnk.one/story/',
  persistPlotServerPrefix: 'https://pipeline.beta.pnk.one/plot/',
  captionContestUrl: 'https://intro.storyverse.pnk.one/caption-contest/alpha/',
  passthroughParams: ['resetPlot'],
  appUrlOverride: true,
  templateApiPrefix: 'https://pipeline.beta.pnk.one/templates/visible/',
  assetsApiPrefix: 'https://pipeline.beta.pnk.one/assets/',
  foundersPassContract: '0x8c2220db0CCB009Da045744e40e1aCF54A51a478',
  tokenproofAppId: 'a0ba37f9-c85e-42e7-8416-8022c222b2ad',
  tokenproofEnv: undefined,
  pipelineTokenproofPrefix: 'https://pipeline.beta.pnk.one/tp/',
  pipelineBaseUrl: 'https://pipeline.beta.pnk.one',
  treatReceiptErrorAsSuccess: false,
  treatWaitForMintAsSuccess: false,
  showMintSuccessError: false,
  mbaasApiKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3MjY2YjNlOC05MmY0LTQyOTQtYWIyYi00OGY5YWZmYmJlNzAiLCJpYXQiOjE2NzQ3NzA5MDAsInN1YiI6IjIifQ.xb5HPSHR_Ah0whN21EwT4a2L_iXEYrDrrRjYYHB3xzA',
  mbaasBaseUrl: 'https://amgpm7yoizg27p4gm7qb3ehsiu.multibaas.com',
  viewPageRoot: {
    walletAddress: '0x0',
    storyId: 'ce47cc1cd025af8bafdb2f96836fd5af126cdba3cd5bba6a405f961e6a3fd641',
    isNFT: false,
    isAi: true,
  },
  showEmailSignUpForWhitelist: false,
  mediaServiceBaseUrl: 'https://mediaservice.pnk.one',
  authBaseUrl: 'https://auth.beta.pnk.one',
  hcaptchaSiteKey: 'be6dbd1c-34c8-4d5c-821f-e2cde36f4eba',
  walletConnectId: '6f681e95dc58090ddf9765d63a1409a1',
  walletConnectorVersion: 2,
  chainIds: [5],
  delegatecashApi: 'rest',
};
