/* eslint-disable quote-props */
import { useMintState } from '@context/mint/MintContext';
import './PremintFaqs.scss';

function BAYCFaqs() {
  return (
    <div className="premint-faqs">
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What is it?</h5>
        <p className="qs-body">
          BAYC Holders can add their Ape inside of Storyverse, thus giving it Storyverse’s features including the ability to be in collectible stories and earn
          royalties.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">How do I mint?</h5>
        <p className="qs-body">
          BAYC holders can authenticate through Tokenproof to validate they have a BAYC. After this you can mint your Character Pass(s) with any wallet.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Storyverse?</h5>
        <p className="qs-body">The home of collectible stories.</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What does a Character Pass do?</h5>
        <p className="qs-body">The worlds best authors will create collectible stories featuring your character which will earn you royalties.</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Wen Mint?</h5>
        <p className="qs-body">1/27/23, 11 AM PST</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Cost?</h5>
        <p className="qs-body">FREE, you pay GAS</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Supply?</h5>
        <p className="qs-body">3000</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Tokenproof?</h5>
        <p className="qs-body">
          tokenproof empowers brands to connect with their audiences through the power of token-based experiences, both online and IRL. tokenproof offers a
          suite of products including IRL token-gates, event ticketing, as well as e-commerce and retail gating, among others.
        </p>
      </div>
    </div>
  );
}

function MAYCFaqs() {
  return (
    <div className="premint-faqs">
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What is it?</h5>
        <p className="qs-body">
          MAYC Holders can add their Ape inside of Storyverse, thus giving it Storyverse’s features including the ability to be in collectible stories and earn
          royalties.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">How do I mint?</h5>
        <p className="qs-body">
          MAYC holders can authenticate through Tokenproof to validate they have a MAYC. After this you can mint your Character Pass(s) with any wallet.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Storyverse?</h5>
        <p className="qs-body">The home of collectible stories.</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Character Pass benefits?</h5>
        <ul className="qs-list">
          <li>YOUR Ape in Collectible Stories</li>
          <li>Earn royalties with your Ape</li>
          <li>YOUR Ape animated</li>
        </ul>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Wen Mint?</h5>
        <p className="qs-body">Live Now!</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Cost?</h5>
        <p className="qs-body">FREE, you pay GAS</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Supply?</h5>
        <p className="qs-body">20000</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s tokenproof?</h5>
        <p className="qs-body">
          tokenproof is the next-generation token gating solution enabling consumers and verifiers to securely authenticate ownership of NFTs while removing any
          risk of theft or loss.
        </p>
      </div>
    </div>
  );
}

function CryptopunksFaqs() {
  return (
    <div className="premint-faqs">
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What is it?</h5>
        <p className="qs-body">
          CryptoPunk Holders can add their Punk inside of Storyverse, thus giving it Storyverse’s features including the ability to be in NFT games and earn
          royalties.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">How do I mint?</h5>
        <p className="qs-body">
          CryptoPunk holders can mint using a delegated wallet (
          <a href="https://delegate.xyz/" target="_blank">
            delegate.cash
          </a>
          ) that has their Punk in it or straight from their cold wallet.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Storyverse?</h5>
        <p className="qs-body">The home of collectible stories.</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What does a Character Pass do?</h5>
        <ul className="qs-list">
          <li>
            By minting a Character Pass, Punks will be able to have their character in animated, playable stories written by award-winning writers, while
            earning royalties.
          </li>
          <li>These collections will be curated by Storyverse.</li>
          <li>Collectors can buy, sell, & PLAY these Story NFTs.</li>
          <li>A Character Pass is essentially a license to be in Story NFTs.</li>
        </ul>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">When Mint?</h5>
        <p className="qs-body">Live Now!</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Cost?</h5>
        <p className="qs-body">FREE, you pay GAS</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Supply?</h5>
        <p className="qs-body">10,000</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Delegate Cash?</h5>
        <p className="qs-body">
          <a href="https://delegate.xyz/" target="_blank">
            delegate.cash
          </a>{' '}
          is a solution to secure valuable onchain assets while still participating in NFT airdrop claims, token governance, and more. Users link cold wallets
          with hot wallets, and then the hot wallet can act on behalf of the cold wallet, like crypto power-of-attorney. Storyverse is allowing you to use
          Delegate Cash instead if you don’t want to use your “Cold Wallet”. You can head over to{' '}
          <a href="https://delegate.xyz/" target="_blank">
            https://delegate.xyz
          </a>{' '}
          to set this up.
        </p>
      </div>
    </div>
  );
}

function PenguinsFaqs() {
  return (
    <div className="premint-faqs">
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What is it?</h5>
        <p className="qs-body">
          Pudgy Penguin Holders can add their Penguin inside of Storyverse, thus giving it Storyverse’s features including the ability to be in NFT games and
          earn royalties.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">How do I mint?</h5>
        <p className="qs-body">
          Pudgy Penguin holders can mint using a delegated wallet (
          <a href="https://delegate.xyz/" target="_blank">
            delegate.cash
          </a>
          ) that has their Penguin in it or straight from their cold wallet.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Storyverse?</h5>
        <p className="qs-body">The home of collectible stories.</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What does a Character Pass do?</h5>
        <ul className="qs-list">
          <li>
            By minting a Character Pass, Penguins will be able to have their character in animated, playable stories written by award-winning writers, while
            earning royalties
          </li>
          <li>These collections will be curated by Storyverse.</li>
          <li>Collectors can buy, sell, & PLAY these Story NFTs.</li>
          <li>A Character Pass is essentially a license to be in Story NFTs.</li>
        </ul>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">When Mint?</h5>
        <p className="qs-body">Live Now!</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Cost?</h5>
        <p className="qs-body">FREE, you pay GAS</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Supply?</h5>
        <p className="qs-body">8,888</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Delegate Cash?</h5>
        <p className="qs-body">
          <a href="https://delegate.xyz/" target="_blank">
            delegate.cash
          </a>{' '}
          is a solution to secure valuable onchain assets while still participating in NFT airdrop claims, token governance, and more. Users link cold wallets
          with hot wallets, and then the hot wallet can act on behalf of the cold wallet, like crypto power-of-attorney. Storyverse is allowing you to use
          Delegate Cash instead if you don’t want to use your “Cold Wallet”. You can head over to{' '}
          <a href="https://delegate.xyz/" target="_blank">
            https://delegate.xyz
          </a>{' '}
          to set this up.
        </p>
      </div>
    </div>
  );
}

function DoodlesFaqs() {
  return (
    <div className="premint-faqs">
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What is it?</h5>
        <p className="qs-body">
          Doodle holders can add their Doodle inside of Storyverse, thus giving it Storyverse’s features including the ability to be in NFT games and earn
          royalties.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">How do I mint?</h5>
        <p className="qs-body">
          Doodle holders can mint using a delegated wallet (
          <a href="https://delegate.xyz/" target="_blank">
            delegate.cash
          </a>
          ) that has their Doodle in it or straight from their cold wallet.
        </p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Storyverse?</h5>
        <p className="qs-body">The home of Story NFT Games.</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What does a Character Pass do?</h5>
        <ul className="qs-list">
          <li>
            By minting a Character Pass, Doodles will be able to have their character in animated, playable stories written by award-winning writers, while
            earning royalties
          </li>
          <li>These collections will be curated by Storyverse.</li>
          <li>Collectors can buy, sell, & PLAY these Story NFTs.</li>
          <li>A Character Pass is essentially a license to be in Story NFTs.</li>
        </ul>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">When Mint?</h5>
        <p className="qs-body">Live Now!</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Cost?</h5>
        <p className="qs-body">FREE, you pay GAS</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">Supply?</h5>
        <p className="qs-body">10,000</p>
      </div>
      <div className="qs-card">
        <h5 className="mint-gradient-title qs-title">What’s Delegate Cash?</h5>
        <p className="qs-body">
          <a href="https://delegate.xyz/" target="_blank">
            delegate.cash
          </a>{' '}
          is a solution to secure valuable onchain assets while still participating in NFT airdrop claims, token governance, and more. Users link cold wallets
          with hot wallets, and then the hot wallet can act on behalf of the cold wallet, like crypto power-of-attorney. Storyverse is allowing you to use
          Delegate Cash instead if you don’t want to use your “Cold Wallet”. You can head over to{' '}
          <a href="https://delegate.xyz/" target="_blank">
            https://delegate.xyz
          </a>{' '}
          to set this up.
        </p>
      </div>
    </div>
  );
}

type SaleFaqConfig = {
  element: JSX.Element;
};

const saleFaqMap: Record<string, SaleFaqConfig> = {
  BAYC: {
    element: <BAYCFaqs />,
  },
  MAYC: {
    element: <MAYCFaqs />,
  },
  CPCP: {
    element: <CryptopunksFaqs />,
  },
  PPCP: {
    element: <PenguinsFaqs />,
  },
  DOODLECP: {
    element: <DoodlesFaqs />,
  },
};

export function PremintFaqs(): JSX.Element {
  const mintState = useMintState();
  const faqElement = mintState.sale ? saleFaqMap[mintState.sale.saleId]?.element ?? null : null;

  return faqElement;
}
