import {
  ClaimPayload,
  ClaimResponse,
  FetchClaimStatusPayload,
  FetchClaimStatusResponse,
  MBMintPayload,
  MBMintResponse,
  SaleService,
  TpSignMintPayload,
  TpSignMintResponse,
  TpWhitelistPayload,
  TpWhitelistResponse,
} from './SaleService';
import { minutesToMs, msToSeconds, secondsToMs } from '@common/NumberUtils';
import { ethers } from 'ethers';
import { mainSuite } from '../ServiceFactory';
import { waitForExpectedValue } from '@common/WaitUtils';
import { DebounceWaiter } from '@common/DebounceWaiter';
import { debug } from '@common/LogWrapper';
import { MintingError, MintingReceiptError, PrepareMintError, SignForMintError, WaitForMintingOfError } from '@errors';
import Sale from '@common/Sale';
import { extendSales, getDatesFromSaleKind, getRelativeFlagFromDates, getSaleDateFlagsFromTime, mergeData, SaleDateFlag } from '@common/SaleUtils';
import { SaleState } from '@common/SaleState';
import { salePrioWeightPicker } from '@common/SalePicker';
import { AnalyticsService } from '../analytics/AnalyticsService';
import { AnalyticsEventName } from '../analytics/AnalyticsEventName';
import * as Sentry from '@sentry/react';
import { mbaasApiCall, MbaasEndpoint, pipelineApiCall, PipelineEndpoint } from '@common/SvsRestApi';
import { ContractAddress, WalletAddress } from '@storyverseco/svs-types';
import { GetSaleContractInfoOpts, SaleContractInfo, SvsNavbarEvent } from '@storyverseco/svs-navbar';
import { waitForMyToken } from '@common/ChainwatcherWaiter';
import { getConfig } from '@common/GetConfig';

const log = debug('app:services:RestSaleService');

/*
    to?: string;
    from?: string;
    nonce?: BigNumberish;
    gasLimit?: BigNumberish;
    gasPrice?: BigNumberish;
    data?: BytesLike;
    value?: BigNumberish;
    chainId?: number;
    type?: number;
    accessList?: AccessListish;
    maxPriorityFeePerGas?: BigNumberish;
    maxFeePerGas?: BigNumberish;
    customData?: Record<string, any>;
    ccipReadEnabled?: boolean;
*/
function formatEthersTx(txFromAPI: any) {
  const tx = JSON.parse(JSON.stringify(txFromAPI));
  tx.gasLimit = tx.gas;
  tx.value = ethers.BigNumber.from(tx.value);
  delete tx.gas;
  delete tx.from;
  delete tx.hash;
  delete tx.gasFeeCap;
  delete tx.gasTipCap;
  return tx;
}

function isSignForMintCountsBody(obj: any): obj is { error: string; counts: { pending: number; minted: number; signable: number } } {
  return (
    typeof obj === 'object' &&
    typeof obj.error === 'string' &&
    typeof obj.counts === 'object' &&
    typeof obj.counts.pending === 'number' &&
    typeof obj.counts.minted === 'number' &&
    typeof obj.counts.signable === 'number'
  );
}

type GameSaleContractInfo = SaleContractInfo & { saleName: string };

export class RestSaleService implements SaleService {
  private sales: Sale[] = null;
  private saleMap: Record<string, Sale> = null;

  private fetchCurSaleWaiter = new DebounceWaiter<{ sale: Sale; saleState: SaleState }>();
  private populateSalesWaiter = new DebounceWaiter<Sale[]>();
  private fetchWLSpotsWaiter = new DebounceWaiter<number>();
  private addToWLWaiter = new DebounceWaiter<TpWhitelistResponse>();
  private signForMintWaiter = new DebounceWaiter<TpSignMintResponse>();
  private mintWithSigWaiter = new DebounceWaiter<MBMintResponse>();

  constructor(private analyticsService: AnalyticsService) {}

  async fetchCurrentSale(saleType?: string): Promise<{ sale: Sale; saleState: SaleState }> {
    await this.populateSales();
    return await this.fetchCurSaleWaiter.wrap(() => {
      const sales = saleType ? this.sales.filter((sale) => sale.saleType === saleType) : this.sales;
      return salePrioWeightPicker(sales, this);
    });
  }
  async fetchSale(saleId: string): Promise<Sale> {
    await this.populateSales();
    return this.saleMap[saleId];
  }
  async fetchSaleBy(opts: { authorAddress?: string; storyId?: string; listingUri?: string; defaultStoryName?: string }): Promise<Sale | undefined> {
    await this.populateSales();
    if (opts.defaultStoryName) {
      return this.sales.find((sale: any) => sale.defaultStoryPath === opts.defaultStoryName);
    }
    if (opts.listingUri) {
      return this.sales.find((sale: any) => sale.listing?.url === `/raffle/${opts.listingUri}`);
    }
    return this.sales.find((sale) => sale.nftWalletAddress === opts.authorAddress && sale.nftStoryId === opts.storyId);
  }
  async fetchAllSales(): Promise<Sale[]> {
    await this.populateSales();
    return this.sales.slice(); // shallow clone so the array won't get affected
  }
  async fetchWhitelistMintRemainingSupply({ saleId }: { saleId: string }): Promise<number> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    // TODO implement when there's an endpoint
    return 1000;
  }
  async fetchPublicMintRemainingSupply({ saleId }: { saleId: string }): Promise<number> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    // TODO implement when there's an endpoint
    return 1000;
  }

  async fetchWhitelistRemainingSpots({ saleId }: { saleId: string }): Promise<number> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    return await this.fetchWLSpotsWaiter.wrap(async () => {
      const tokenType = sale.tokenType;

      // const data = await pipelineApiCall({
      //   method: 'GET',
      //   endpoint: PipelineEndpoint.WlSpotsRemaining,
      //   tokens: { tokenType },
      //   retryOpts: {
      //     maxRetries: 3,
      //     retryDelay: 1000,
      //   },
      // });
      // log('fetchWhitelistRemainingSpots', tokenType, data);
      // if (data.startsWith('"') && data.endsWith('"')) {
      //   return parseInt(JSON.parse(data) as string);
      // }
      return parseInt('100');
    });
  }

  async fetchClaimStatus({
    saleId,
    getClaimStatusPayload,
  }: {
    saleId: string;
    getClaimStatusPayload: FetchClaimStatusPayload;
  }): Promise<FetchClaimStatusResponse> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    const tokenType = sale.tokenType;
    const data = await pipelineApiCall({
      method: 'GET',
      endpoint: PipelineEndpoint.SetClaimed,
      tokens: {
        walletAddress: getClaimStatusPayload.walletAddress,
        saleId: tokenType,
      },
    });

    log('getClaimStatus response data', data);

    return data;
  }

  async claim({ saleId, claimPayload }: { saleId: string; claimPayload: ClaimPayload }): Promise<ClaimResponse> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    const tokenType = sale.tokenType;
    const data = await pipelineApiCall({
      method: 'GET',
      endpoint: PipelineEndpoint.SetClaimed,
      tokens: {
        walletAddress: claimPayload.walletAddress,
        contractKey: tokenType,
      },
    });

    log('claim response data', data);

    return data;
  }

  /**
   * This function is used by the game to get the public mint sale contract info,
   * in order to know whether we should allow them to mint from the game as well as
   * to redirect them to their own story (in case they are viewing someone else's story)
   */
  async fetchSaleContractInfo({ authorAddress, storyId }: GetSaleContractInfoOpts): Promise<GameSaleContractInfo> {
    await this.populateSales();

    const sale = this.sales.find((s) => s.nftWalletAddress?.toLowerCase() === authorAddress.toLowerCase() && s.nftStoryId === storyId);
    if (!sale) {
      throw new Error(`Error (fetchSaleContract): Could not find sale for '${authorAddress}/${storyId}'.`);
    }

    const saleDate = getDatesFromSaleKind(sale, SaleDateFlag.PublicMint);

    const saleState = getRelativeFlagFromDates(saleDate, Date.now());

    return {
      openMint: saleState === SaleDateFlag.During,
      contractAddress: sale.tokenContractAddress as WalletAddress,
      contractKey: sale.tokenType,
      saleName: sale.saleName,
    };
  }

  async contestStatus(opts: { saleId: string; walletAddress: string }): Promise<{ winner: boolean; claimed: boolean }> {
    await this.populateSales();
    const sale = this.saleMap[opts.saleId];
    try {
      const data = await pipelineApiCall({
        method: 'GET',
        endpoint: PipelineEndpoint.GetContestStatus,
        tokens: {
          contractKey: sale.tokenType,
          walletAddress: opts.walletAddress,
        },
      });
      return data;
    } catch (e) {
      throw e;
    }
  }

  async addToWhitelist({ saleId, whitelistPayload }: { saleId: string; whitelistPayload: TpWhitelistPayload }): Promise<TpWhitelistResponse> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    return await this.addToWLWaiter.wrap(async () => {
      const tokenType = sale.tokenType;
      const data = await pipelineApiCall({
        method: 'POST',
        endpoint: PipelineEndpoint.WlAdd,
        tokens: { tokenType },
        body: whitelistPayload,
      });
      log('addToWhitelist', tokenType, data);

      return {
        added: data.added.length as number,
        count: data.count as number,
        storyKey: data.storyKey as string,
      };
    });
  }
  async signForMint({ saleId, payload }: { saleId: string; payload: TpSignMintPayload }): Promise<TpSignMintResponse> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    const { navbarService } = mainSuite;

    return await this.signForMintWaiter
      .wrap(async () => {
        // need to determine if it's premint or mint via relative flag.
        // also a place to check if it's actually during a mint
        const saleDateFlags = getSaleDateFlagsFromTime(sale);
        let premint: string;
        switch (saleDateFlags) {
          case SaleDateFlag.DuringPublicMint:
            premint = '';
            break;
          case SaleDateFlag.DuringWhitelistMint:
            premint = 'premint';
            break;
          default:
            throw new Error('Currently not in minting period.');
        }
        const tokenType = sale.tokenType;
        let body: Record<string, string | number> = {
          mintTo: payload.hotWallet.toLowerCase(),
          tokenQuantity: payload.tokenQuantity || 1,
        };

        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintPresignStarted, { saleId });
        body = sale.tokenproofPolicyId ? { ...body, nonce: payload.nonce, policyId: sale.tokenproofPolicyId } : body;
        log('signForMint body', body);
        const data = await pipelineApiCall({
          method: 'POST',
          endpoint: sale.saleType == 'collection' ? PipelineEndpoint.StoryNFTSignForMint : PipelineEndpoint.SignForMint,
          tokens: { tokenType, premint },
          body,
        });
        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintPresignEnded, { saleId, responseData: data });
        log('signForMint response', data);

        const response = data
          ? ({
              rawMessage: data.rawMessage,
              signature: data.sig ?? data.signature,
              storyKey: data.storyKey,
              signedTokens: data.signedTokens,
            } as TpSignMintResponse)
          : null;

        if (sale.saleType == 'collection' && data.platform) {
          response.platform = data.platform;
        }

        this.signForMintWaiter.set(response);

        return response;
      })
      .catch((e) => {
        // wrap any error here as a SignForMintError
        let message = e.message;
        if (e.restApiError) {
          // use message from error if it's a specific data format
          const responseJson = e.responseJson;
          if (responseJson && isSignForMintCountsBody(responseJson)) {
            if (responseJson.counts.pending > 0) {
              message = 'You are already in the process of minting some!';
            } else if (responseJson.counts.minted > 0) {
              message = 'You have already minted some.';
            } else {
              message = responseJson.error;
            }
          }
        }
        const error = new SignForMintError(
          `${message}\n(hot wallet: "${payload.hotWallet}", nonce: "${payload.nonce}",\ncoldWallet: ${payload.coldWallet}\ntokenQuantity: ${payload.tokenQuantity}`,
        );
        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintPresignError, error);
        throw error;
      });
  }
  async mintWithSignature({ saleId, payload }: { saleId: string; payload: MBMintPayload }): Promise<MBMintResponse> {
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    const tokenType = sale.tokenType;

    this.analyticsService.track(AnalyticsEventName.MintStart, { tokenType });

    const startTime = Date.now();
    const maxTrackTime = minutesToMs(5);
    const trackIntervalId = setInterval(() => {
      const deltaTime = Date.now() - startTime;
      const readableTime = msToSeconds(deltaTime).toFixed(1);
      if (deltaTime < maxTrackTime) {
        Sentry.captureMessage(`mintWithSignature: minting duration: ${readableTime}s`, 'debug');
      } else {
        Sentry.captureMessage(`mintWithSignature: minting is taking more than max time (${readableTime}s)`, 'warning');
        clearInterval(trackIntervalId);
      }
    }, secondsToMs(30));

    // do we need to check if it's during mint? they can't get to this point without signForMint.
    return await this.mintWithSigWaiter
      .wrap(async () => {
        const mintBody = {
          args: sale.saleType === 'collection' ? [payload.rawMessage, payload.platform, payload.signature] : [payload.rawMessage, payload.signature],
          from: payload.hotWallet.toLowerCase(),
          signer: payload.hotWallet.toLowerCase(),
          value: sale.saleType === 'collection' ? payload.platform : ethers.BigNumber.from('0').toString(),
        };
        log('mintBody', mintBody);

        console.error('mintBody', JSON.stringify({ mintBody }, null, 2));

        const { navbarService } = mainSuite;

        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTxCreateStarted, { saleId });
        let mintData: any;
        try {
          const sharedLabel = sale.saleType == 'collection' ? 'ethsale_storynft' : 'ethsale_character_pass';
          mintData = await mbaasApiCall({
            method: 'POST',
            endpoint: MbaasEndpoint.Mint,
            tokens: { addressLabel: sale.mbaasAddressLabel, sharedLabel },
            body: mintBody,
          });
          navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTxCreateEnded, { saleId, mintData });
          log('mintData', mintData);
          console.error('mintData', JSON.stringify({ mintData }, null, 2));
          // in case sentry filters due to length:
          const logParts: any[] = [];
          if (mintData) {
            logParts.push('status:', mintData.status);
            if (mintData.result) {
              if (mintData.result.tx) {
                logParts.push('result.tx.from:', mintData.result.tx.from);
                logParts.push('result.tx.to:', mintData.result.tx.to);
                logParts.push('result.tx.value:', mintData.result.tx.value);
                logParts.push('result.tx.hash:', mintData.result.tx.hash);
              }
              if ('submitted' in mintData.result) {
                logParts.push('result.submitted:', mintData.result.submitted);
              }
            } else {
              logParts.push('no result');
            }
          } else {
            logParts.push('no mintData');
          }
          log('mintData details:', ...logParts);
        } catch (e) {
          console.error('error: mbaasApiCall:Mint', e.message);
          navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTxCreateError, e);
          throw new PrepareMintError(
            `${e.message}\nmintWithSig txcreate rawMessage: ${payload.rawMessage}\nSignature: ${payload.signature}\nPlatform: ${payload.platform}`,
          );
        }

        log('extracting tx from mbaas response');
        const {
          result: { tx, submitted },
        } = mintData;

        log('formatting tx for ethers.js');
        // Format the transaction so that ethers.js can sign it
        const ethersTx = formatEthersTx(tx);
        // Submit the transaction to the blockchain
        let txResponse: ethers.providers.TransactionResponse;

        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintStarted, { saleId, transaction: tx });
        console.error('ethersTx', JSON.stringify({ ethersTx }, null, 2));
        try {
          log('sending tx to blockchain...');
          txResponse = await navbarService.api.sendTransaction(ethersTx);
          log('sent to blockchain');
          console.error('sent to blockchain', JSON.stringify({ txResponse }, null, 2));
        } catch (e) {
          console.error('error: sendTransaction', e.message);
          navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintError, e);
          throw new MintingError(`${e.message}\ntx: ${JSON.stringify(tx)}\nethersTx: ${JSON.stringify(ethersTx)}`);
        }
        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintEnded, { saleId, transactionResponse: txResponse });

        // wait for receipt
        const txHash = typeof txResponse === 'string' ? txResponse : txResponse.hash;
        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTxWaitStarted, { saleId, txHash });
        log('waiting for txHash:', txHash);
        let txReceipt: ethers.providers.TransactionReceipt;
        console.error('waiting for txHash:', JSON.stringify({ txHash }, null, 2));
        try {
          txReceipt = await this.waitForTransactionReceipt(txHash);
          log('txReceipt:', txReceipt);
          // in case sentry filters due to length:
          log(
            'txReceipt details:',
            'to:',
            txReceipt.to,
            'from:',
            txReceipt.from,
            'blockHash:',
            txReceipt.blockHash,
            'blockNumber:',
            txReceipt.blockNumber,
            'status:',
            txReceipt.status,
          );
        } catch (e) {
          console.error('waitForTransactionReceipt', e.message);
          navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTxWaitError, e);
          throw new MintingReceiptError(
            `${e.message}\nmintWithSig txwait rawMessage: ${payload.rawMessage}\nSignature: ${payload.signature}\nPlatform: ${payload.platform}`,
          );
        }

        if ('status' in txReceipt && !txReceipt.status) {
          const error = new MintingReceiptError(
            `Receipt reports status failed\n mintWithSig txwait rawMessage: ${payload.rawMessage}\nSignature: ${payload.signature}\nPlatform: ${payload.platform}`,
          );
          navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTxWaitError, error);
          throw error;
        }

        const tokenType = sale.tokenType;

        // record minting
        if (sale.saleType == 'characterPass') {
          let waitBody: Record<string, string> = {
            txHash,
            mintTo: payload.hotWallet.toLowerCase(),
            twitter: payload.twitter,
          };

          waitBody = sale.tokenproofPolicyId ? { ...waitBody, nonce: payload.nonce } : waitBody;
          log('waitBody', waitBody);
          try {
            const waitData = await pipelineApiCall({
              method: 'POST',
              endpoint: PipelineEndpoint.ConfirmMintInitiated,
              tokens: { tokenType },
              body: waitBody,
              retryOpts: {
                maxRetries: 3,
                retryDelay: 1000,
                retryCallback(reason, retries, maxRetries) {
                  log(`ConfirmMintInitiated error. Retrying... (${retries}/${maxRetries}).`, reason?.message ?? reason);
                },
              },
            });
            log('waitData', waitData);
          } catch (e) {
            const error = new WaitForMintingOfError(
              `${e.message}\nmintWithSig txwait rawMessage: ${payload.rawMessage}\nSignature: ${payload.signature}\nPlatform: ${payload.platform}`,
            );
            navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTxWaitError, error);
            throw error;
          }
        }

        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTxWaitEnded, { saleId, txHash });

        this.mintWithSigWaiter.set(null);
        return null;
      })
      .finally(() => {
        clearInterval(trackIntervalId);
      });
  }

  async publicSignAndMint({
    saleId,
    walletAddress,
    tokenQuantity,
  }: {
    saleId: string;
    walletAddress: WalletAddress;
    tokenQuantity?: number;
  }): Promise<MBMintResponse> {
    return this.signForMint({
      saleId,
      payload: {
        hotWallet: walletAddress,
        tokenQuantity, // if undefined, json.stringify will remove this field
      },
    })
      .then((response) =>
        this.mintWithSignature({
          saleId,
          payload: {
            hotWallet: walletAddress,
            rawMessage: response.rawMessage,
            signature: response.signature,
            platform: response.platform,
          },
        }),
      )
      .then(async () => {
        const sale = await mainSuite.saleService.fetchSale(saleId);
        const { navbarService } = mainSuite;
        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTokenWaitStarted, { saleId });
        let token: any;
        try {
          token = await waitForMyToken(sale.tokenContractAddress as ContractAddress);
        } catch (e) {
          navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTokenWaitError, e);
          // rethrow
          throw e;
        }
        navbarService.emitAndSendClientEvent(SvsNavbarEvent.MintTokenWaitEnded, { saleId, token });
        return token;
      });
  }

  async fetchThumbnails({ saleId, tokenIds }: { saleId: string; tokenIds: string[] }): Promise<Record<string, string>> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    const tokenType = sale.tokenType;
    const data = pipelineApiCall({
      method: 'POST',
      endpoint: PipelineEndpoint.TokenThumbnails,
      tokens: { tokenType },
      body: { tokenIds },
      retryOpts: {
        maxRetries: 3,
        retryDelay: 1000,
      },
    });
    return data;
  }
  async fetchNFTContractAddress({ saleId }: { saleId: string }): Promise<string> {
    await this.populateSales();
    const sale = this.saleMap[saleId];
    if (!sale) {
      throw new Error(`Invalid sale ID "${saleId}"`);
    }

    return sale.tokenContractAddress;
  }

  private waitForTransactionReceipt(txHash: string | Promise<string>): Promise<ethers.providers.TransactionReceipt> {
    const { navbarService } = mainSuite;
    const { promise, cancel } = waitForExpectedValue(() => navbarService.api.getTransactionReceipt(txHash), {
      retryDelay: 1000,
      maxRetries: 600, // roughly 10 minutes
      retryOnError: false,
    });
    return promise;
  }

  private async populateSales() {
    if (this.sales) {
      return;
    }
    await this.populateSalesWaiter.wrap(async () => {
      const cfg = await getConfig();
      const cfgSales = Object.values(cfg.saleData);
      const module = await import('@assets/data/saleData');
      const { sales: envSales, saleOverrides, createMintDateOverrides } = module.saleExtra;

      const mergedSales = mergeData(cfgSales, envSales);
      const mintDateOverrides = createMintDateOverrides?.(mergedSales) ?? [];

      this.sales = extendSales(mergedSales, saleOverrides, mintDateOverrides);

      this.saleMap = this.sales.reduce(
        (map, sale) => ({
          ...map,
          [sale.saleId]: sale, // case-sensitive
          [sale.saleId.toLowerCase()]: sale, // not case-sensitive
        }),
        {},
      );

      return this.sales;
    });
  }
}
