import * as cfg from '@storyverseco/svs-config-client';
import { environment } from '@environment';

let config: cfg.ConfigClient;

export const getConfig = async () => {
  if (!config) {
    config = await cfg
      .stage(environment.production ? 'prod' : 'dev')
      .addSource('frontend')
      .addSource('saleData')
      .loadConfigs(true).promise;
  }
  return config;
};
