export enum SearchParam {
  SignUp = 'signup',
  Map = 'map',
  Maps = 'maps',
  Whitepaper = 'whitepaper',
  AppUrl = 'appUrl',
  NoVideo = 'novideo',
  FullVideo = 'fullvideo',
  Tokenproof = 'tokenproof',
  Redirect = 'r',
  ForcePage = 'forcePage',
  Auth = 'auth',
  LinkId = 'lid',
  AmplitudeDeviceId = 'adid',
  SkipAuth = 'ska', // avoiding "sa" as an abbreviation
}
