import amplitude, { AmplitudeClient } from 'amplitude-js';
import { HostedApp } from '@components/hosted-app/HostedApp';
import { environment } from '@environment';
import { usePassthroughParams } from '@hooks/usePassthroughParams';
import { Page } from '../Page';
import './ViewPage.scss';
import { useAppUrl } from '@hooks/useAppUrl';
import { GameSiteUrlParams } from '@storyverseco/svs-consts';
import { useParamsToSearchParams } from '@hooks/useParamsToSearchParams';
import { useEffect, useMemo } from 'react';
import { useUserHook } from '@hooks/useUserHook';
import { SvsProvider } from '@storyverseco/svs-navbar';
import { GameMintBox } from '@components/MintBox/GameMintBox';
import { MintActionType, useMintDispatch, useMintState } from '@context/mint/MintContext';
import { SearchParam } from '@common/SearchParam';
import { fetchSaleState } from '@common/SalePicker';
import { mainSuite } from '@services/ServiceFactory';

const predefinedSearchParams = [{ key: 'iframed', value: '1' }];

// page param to search param mapping for story links
const storyMapping = {
  [GameSiteUrlParams.WalletAddress]: GameSiteUrlParams.WalletAddress,
  [GameSiteUrlParams.StoryId]: GameSiteUrlParams.StoryId,
  [GameSiteUrlParams.TokenID]: GameSiteUrlParams.TokenID,
  [GameSiteUrlParams.DeeplinkTimelineId]: GameSiteUrlParams.DeeplinkTimelineId,
  [GameSiteUrlParams.DeeplinkStoryId]: GameSiteUrlParams.DeeplinkStoryId,
  [GameSiteUrlParams.DeeplinkChoiceId]: GameSiteUrlParams.DeeplinkChoiceId,
  [SearchParam.LinkId]: SearchParam.LinkId,
  [SearchParam.AmplitudeDeviceId]: SearchParam.AmplitudeDeviceId,
};

interface ViewPageProps {
  walletAddress?: string;
  storyId?: string;
  tokenId?: string;
  isNFT?: boolean;
  isAi?: boolean;
}
// allows empty strings, since empty strings are falsy
function isStrDefined(str: string | null) {
  return typeof str === 'string';
}

export function ViewPage(props: ViewPageProps) {
  const { sale } = useMintState();
  const mintDispatch = useMintDispatch();

  // Not sure what this does on its own
  useUserHook({ providerType: SvsProvider.WalletConnect });

  // Send event to game amplitude rather than site amplitude
  useEffect(() => {
    if (!environment.ampGameId) {
      return;
    }
    const amplitudeGameInstance = amplitude.getInstance('game');
    amplitudeGameInstance.init(environment.ampGameId, undefined, {
      apiEndpoint: environment.ampProxy || undefined,
      deviceId: mainSuite.analyticsService.deviceId,
    });
    amplitudeGameInstance.logEvent('ViewerLoadStart');
  }, []);

  // convert page params to search params
  let convertedParams = useParamsToSearchParams(storyMapping);

  // combine search params
  const includedParams = useMemo(() => {
    let updatedParams = [...convertedParams];

    // wallet address override
    if (props.walletAddress) {
      const newParam = { key: GameSiteUrlParams.WalletAddress, value: props.walletAddress };
      const index = updatedParams.findIndex((param) => param.key === GameSiteUrlParams.WalletAddress);
      if (index === -1) {
        updatedParams.push(newParam);
      } else {
        // we're replacing the item, not changing its value, to avoid possible side effects
        updatedParams[index] = newParam;
      }
    }

    // story ID override
    if (props.storyId) {
      const newParam = { key: GameSiteUrlParams.StoryId, value: props.storyId };
      const index = updatedParams.findIndex((param) => param.key === GameSiteUrlParams.StoryId);
      if (index === -1) {
        updatedParams.push(newParam);
      } else {
        // we're replacing the item, not changing its value, to avoid possible side effects
        updatedParams[index] = newParam;
      }
    }

    // token ID override
    if (props.tokenId) {
      const newParam = { key: GameSiteUrlParams.TokenID, value: props.tokenId };
      const index = updatedParams.findIndex((param) => param.key === GameSiteUrlParams.TokenID);
      if (index === -1) {
        updatedParams.push(newParam);
      } else {
        // we're replacing the item, not changing its value, to avoid possible side effects
        updatedParams[index] = newParam;
      }
    }

    if (props.isNFT) {
      updatedParams = [...updatedParams, { key: GameSiteUrlParams.Nft, value: 'true' }];
    }

    if (props.isAi) {
      updatedParams = [...updatedParams, { key: GameSiteUrlParams.Ai, value: 'true' }];
    }

    // inject device id
    updatedParams = [...updatedParams, { key: SearchParam.AmplitudeDeviceId, value: mainSuite.analyticsService.deviceId }];

    const p = [...updatedParams, ...predefinedSearchParams];

    // lowercase the wallet address
    p.forEach((item) => {
      if (item.key === GameSiteUrlParams.WalletAddress) {
        item.value = item.value.toLowerCase();
      }
    });

    return p;
  }, [props, JSON.stringify(convertedParams)]);

  // combine passthrough params with page's search params
  const searchParamsStr = usePassthroughParams(environment.passthroughParams, includedParams);

  // appUrl override if enabled
  const url = useAppUrl(environment.viewUrl, searchParamsStr);

  const gameBuildUrl = useAppUrl(environment.viewUrl.replace('index.html', 'js/index.js'));
  // @perf: trigger the loading of the game JS build before the iframe is initialized
  useEffect(() => {
    fetch(gameBuildUrl);
  }, []);

  /**
 The chunk below is used to preload the sale data so when the game fetches it via navbar the sale is already loaded.
*/
  const { authorAddress, storyId, tokenId } = useMemo(() => {
    if (!searchParamsStr) {
      return {};
    }
    const params = new URLSearchParams(searchParamsStr);
    const authorAddress = props.walletAddress || params.get(GameSiteUrlParams.WalletAddress)?.toLowerCase();
    const storyId = props.storyId || params.get(GameSiteUrlParams.StoryId);
    const tokenId = props.tokenId || params.get(GameSiteUrlParams.TokenID);
    return {
      authorAddress,
      storyId,
      tokenId,
    };
  }, [searchParamsStr, props]);

  // If we don't have a sale for nft viewer, grab it using query param values
  useEffect(() => {
    if (!props.isNFT || !authorAddress || !storyId || Boolean(sale?.saleId)) {
      return;
    }

    const loadSale = async () => {
      const sale = await mainSuite.saleService.fetchSaleBy({ authorAddress, storyId });
      if (!sale) {
        console.error(`Error (getVideoSettings): Could not find sale for authorAddress '${authorAddress}' and storyId '${storyId}'. Skipping...`);
        return;
      }
      const saleState = await fetchSaleState(sale, mainSuite.saleService);
      mintDispatch({
        type: MintActionType.UpdateSale,
        sale,
        saleState,
      });
    };

    if (tokenId) {
      loadSale();
    }
  }, [sale?.saleId, authorAddress, storyId, tokenId]);

  // only allow HostedApp to be shown if url and searchParamsStr are defined
  // to prevent loading twice
  return (
    <Page title="Viewer" className="view-page no-scroll" hideFooter fullHeight hideNavbar analyticsManualEntryFinal>
      {url && isStrDefined(searchParamsStr) && <HostedApp src={url} />}
      {sale?.saleId && <GameMintBox />}
    </Page>
  );
}
