import * as ethers from 'ethers';
import React, { Dispatch } from 'react';
import { UserAccount } from '@common/UserAccount';
import { UserPermission } from '@common/UserPermission';
import { Action } from '@context/GenerateContext';
import { createLoginScreen, MockLoginActionType } from '../mock/MockLoginScreen';
import { MockModal } from '../mock/MockModal';
import { MockStorageService } from '../mock/MockStorageService';
import { waitForTx } from '../mock/MockTxScreen';
import { waitForMs } from '@common/WaitUtils';
import { MockLoginUser, mockUsers, serviceAddressKey } from '../mock/MockUtils';
import { UserActionType } from './UserActionType';
import { UserService } from './UserService';
import { environment } from '@environment';
import { mockChainId } from '@common/ProviderUtils';
import { AnalyticsEventName } from '@services/analytics/AnalyticsEventName';
import { mainSuite } from '@services/ServiceFactory';
import { ChainalysisService } from '../chainalysis/ChainalysisService';
import { ConditionWaiter } from '@common/ConditionWaiter';
import { UserActionProps } from './UserContext';
import { LoginState } from '@common/LoginState';
import { debug } from '@common/LogWrapper';
import { ChangeSvsProviderOpts, SvsProvider, SvsProviderType, Wallet } from '@storyverseco/svs-navbar';
import { ContractAddress } from '@storyverseco/svs-types';

const log = debug('app:services:user:MockUserService');

export type AccountMap = {
  [address: string]: Play.AccountDetails;
};

export type ChainBalanceMap = {
  [address: string]: string;
};

export const userServiceDataKey = 'userServiceData';
export const loggedInAddressKey = 'loggedInAddress';
export const chainBalanceMapKey = 'chainBalanceMap';

function createLoggedOutWallet(): Wallet {
  return {
    address: null,
    flags: {
      founder: false,
      loggedIn: false,
      storyNFTEditor: false,
    },
    nfts: {},
    storyNFTs: {},
    messageAndSig: null,
  };
}

export class MockUserService implements UserService {
  readonly logInPopUp = false;

  private loginModal: MockModal;
  private initWaiter = new ConditionWaiter(false);
  private userStateWaiter = new ConditionWaiter<UserAccount>(null);
  private userDispatchWaiter = new ConditionWaiter<React.Dispatch<Action>>(null);
  private loggedInWaiter = new ConditionWaiter(false);
  private accountMap: AccountMap;
  private chainBalanceMap: ChainBalanceMap;

  private providerType: SvsProviderType = SvsProvider.WalletConnect; // the default

  private wallet: Wallet;

  constructor(private mockService: MockStorageService, private chainalysisService: ChainalysisService) {
    this.wallet = createLoggedOutWallet();
    try {
      this.accountMap = mockService.getJson(userServiceDataKey) ?? {};
    } catch (e) {
      this.accountMap = {};
      mockService.putJson(userServiceDataKey, this.accountMap);
    }
    try {
      this.chainBalanceMap = mockService.getJson(chainBalanceMapKey) ?? {};
    } catch (e) {
      this.chainBalanceMap = {};
      mockService.putJson(chainBalanceMapKey, this.chainBalanceMap);
    }
    this.registerServiceAddress();
    this.initLoggedInUser();
  }

  private async initLoggedInUser() {
    await this.waitForUserState();
    const dispatch = await this.waitForDispatch();

    const loggedInAddress = this.mockService.get(loggedInAddressKey);
    if (!loggedInAddress) {
      this.userDispatchWaiter.wait().then((dispatch) =>
        dispatch({
          type: UserActionType.LogOut,
        }),
      );
      this.initWaiter.set(true);
      return;
    }

    const user: MockLoginUser = mockUsers.find((u) => u.address === loggedInAddress);
    if (!user) {
      this.userDispatchWaiter.wait().then((dispatch) =>
        dispatch({
          type: UserActionType.LogOut,
        }),
      );
      this.initWaiter.set(true);
      return;
    }

    const permissions = [UserPermission.User];
    if (user.service) {
      permissions.push(UserPermission.Admin);
      permissions.push(UserPermission.Service);
    }

    const caSafe = await this.chainalysisService.isWalletAddressSafe(user.address);

    this.mockService.put(loggedInAddressKey, user.address);
    this.wallet = {
      address: user.address,
      flags: {
        founder: false,
        loggedIn: true,
        storyNFTEditor: false,
      },
      nfts: {},
      storyNFTs: {},
      messageAndSig: null,
    };
    dispatch({
      type: UserActionType.LogIn,
      caSafe,
      chainId: mockChainId,
      address: user.address,
      name: user.name,
      permissions,
      wallet: this.wallet,
    });
    this.initWaiter.set(true);
  }

  private registerServiceAddress() {
    const serviceAccount = mockUsers.find((user) => user.service);
    this.mockService.put(serviceAddressKey, serviceAccount.address);
  }

  private startLogin() {
    if (this.loginModal) {
      return;
    }
    mainSuite.analyticsService.track(AnalyticsEventName.WalletConnectStart);
    this.waitForDispatch().then((dispatch) =>
      dispatch({
        type: UserActionType.UpdateLoginState,
        loginState: LoginState.LoggingIn,
      }),
    );
    this.loginModal = createLoginScreen(this.loginCallback.bind(this), this.loginCloseCallback.bind(this), 'Login - Faux Metamask');
  }

  private endLogin() {
    if (!this.loginModal) {
      return;
    }

    this.loginModal.destroy();
    this.loginModal = null;
  }

  private loginCallback(loginActionType: MockLoginActionType, data?: any) {
    switch (loginActionType) {
      case MockLoginActionType.Add:
        // not being used
        break;
      case MockLoginActionType.Select:
        const loginUser: MockLoginUser = data;
        const permissions = [UserPermission.User];
        if (loginUser.service) {
          permissions.push(UserPermission.Admin);
          permissions.push(UserPermission.Service);
        }
        this.mockService.put(loggedInAddressKey, loginUser.address);
        mainSuite.analyticsService.track(AnalyticsEventName.WalletConnect, {
          walletId: loginUser.address,
          walletStatus: 'registered_unlisted',
        });
        this.wallet = {
          address: loginUser.address,
          flags: {
            founder: false,
            loggedIn: true,
            storyNFTEditor: false,
          },
          nfts: {},
          storyNFTs: {},
          messageAndSig: null,
        };
        Promise.all([this.chainalysisService.isWalletAddressSafe(loginUser.address), this.waitForDispatch()]).then(([caSafe, dispatch]) => {
          dispatch({
            type: UserActionType.LogIn,
            caSafe,
            chainId: mockChainId,
            address: loginUser.address,
            name: loginUser.name,
            permissions,
            wallet: this.wallet,
          });
        });
        const account = this.getAccountDetails(loginUser.address);
        account.name = loginUser.name;
        this.putAccountDetails(loginUser.address, account);
        this.endLogin();
        break;
      case MockLoginActionType.Close:
        this.wallet = createLoggedOutWallet();
        this.waitForDispatch().then((dispatch) =>
          dispatch({
            type: UserActionType.LogOut,
          }),
        );
        this.endLogin();
        break;
    }
  }

  private loginCloseCallback() {
    this.wallet = createLoggedOutWallet();
    this.waitForDispatch().then((dispatch) =>
      dispatch({
        type: UserActionType.LogOut,
      }),
    );
    this.endLogin();
  }

  private async checkForLoggedIn(): Promise<void> {
    const userState = await this.waitForUserState();
    this.loggedInWaiter.set(!!userState?.loggedIn);
  }

  private waitForUserState(): Promise<UserAccount> {
    return this.userStateWaiter.wait();
  }

  private waitForDispatch(): Promise<React.Dispatch<UserActionProps>> {
    return this.userDispatchWaiter.wait();
  }

  private waitForLogin(): Promise<boolean> {
    return this.loggedInWaiter.wait();
  }

  private saveChainBalance(): void {
    this.mockService.putJson(chainBalanceMapKey, this.chainBalanceMap);
  }

  getAccountDetails(address: string): Play.AccountDetails {
    if (!this.accountMap[address]) {
      this.accountMap[address] = {
        linked: false,
        balance: null,
        nfts: null,
        packs: null,
        name: null,
      };
    }

    return { ...this.accountMap[address] };
  }

  putAccountDetails(address: string, details: Play.AccountDetails) {
    this.accountMap[address] = details;
    this.mockService.putJson(userServiceDataKey, this.accountMap);
    Promise.all([this.waitForUserState(), this.waitForDispatch()]).then(([userState, userDispatch]) => {
      if (userState.address !== address) {
        return;
      }
      userDispatch({ type: UserActionType.UpdateChainId, chainId: mockChainId });
      // userDispatch({ type: UserActionType.UpdateBalance, balance: details.balance });
      // userDispatch({ type: UserActionType.UpdateNFTs, nfts: details.nfts });
      // userDispatch({ type: UserActionType.UpdatePacks, packs: details.packs });
      userDispatch({ type: UserActionType.UpdateName, name: details.name });
    });
  }

  hasInitialized(): boolean {
    return this.initWaiter.get();
  }
  waitForInit(): Promise<void> {
    return this.initWaiter.wait().then();
  }
  setDispatch(dispatch: Dispatch<Action<UserActionType, UserActionProps>>): void {
    this.userDispatchWaiter.set(dispatch);
  }
  setUserState(state: UserAccount): void {
    this.userStateWaiter.set(state);
    this.checkForLoggedIn();
  }
  signUp(): void {
    this.startLogin();
  }
  logIn(): void {
    this.startLogin();
  }
  logOut(): void {
    this.mockService.remove(loggedInAddressKey);
    this.userDispatchWaiter.wait().then((dispatch) =>
      dispatch({
        type: UserActionType.LogOut,
      }),
    );
  }
  async checkIfLinked(force?: boolean): Promise<boolean> {
    await this.waitForInit();
    const userState = await this.waitForUserState();
    const userDispatch = await this.waitForDispatch();
    if (!userState.loggedIn) {
      return false;
    }
    // if (!force && userState.checked) { return userState.linked; }

    await waitForMs(environment.mockLoadingMs);

    const details = this.getAccountDetails(userState.address);
    userDispatch({ type: UserActionType.UpdateChainId, chainId: mockChainId });
    // userDispatch({ type: UserActionType.UpdateLinked, linked: details.linked });
    // userDispatch({ type: UserActionType.UpdateBalance, balance: details.balance });
    // userDispatch({ type: UserActionType.UpdateNFTs, nfts: details.nfts });
    // userDispatch({ type: UserActionType.UpdatePacks, packs: details.packs });
    // userDispatch({ type: UserActionType.UpdateChecked, checked: true });
    userDispatch({ type: UserActionType.UpdateName, name: details.name });
    return details.linked;
  }
  async maybeLink(): Promise<void> {
    const linked = await this.checkIfLinked();

    if (linked) {
      return;
    }

    return this.link();
  }
  async link(): Promise<void> {
    await waitForTx('Transaction: Approve linking wallet?');
    const userState = await this.waitForUserState();

    const details = this.getAccountDetails(userState.address);
    if (details.balance === null) {
      details.balance = '0.0000000';
    }
    if (details.nfts === null) {
      details.nfts = [];
    }
    if (details.packs === null) {
      details.packs = [];
    }
    details.linked = true;
    this.putAccountDetails(userState.address, details);
    const linked = await this.checkIfLinked(true);
    if (!linked) {
      throw new Error('Linking account failed');
    }
  }
  async loadPublicAccount(address: string): Promise<Play.PublicAccountDetails> {
    await waitForMs(environment.mockLoadingMs);

    const account = this.accountMap[address];
    if (!account) {
      throw new Error('Account not found');
    }

    return {
      nfts: account.nfts,
      packs: account.packs,
      name: account.name,
    };
  }
  async getServiceAddress(): Promise<string> {
    return this.mockService.get(serviceAddressKey);
  }

  async depositIntoWallet(balance: string): Promise<boolean> {
    const userState = await this.waitForUserState();
    const chainBalance = await this.fetchChainBalance(); // this already checks for login and link
    const platformBalance = '0.0'; // userState.balance;
    const chainBalanceDec = ethers.utils.parseEther(chainBalance);
    const platformBalanceDec = ethers.utils.parseEther(platformBalance);
    const depositDec = ethers.utils.parseEther(balance);

    if (depositDec.lte('0.0')) {
      throw new Error('Cannot deposit zero or less');
    }

    if (depositDec.gt(chainBalanceDec)) {
      throw new Error('Cannot deposit more than available balance');
    }

    await waitForTx(`Approve depositing ${balance} into wallet?`);

    const newChainBalanceDec = chainBalanceDec.sub(depositDec);
    const newPlatformBalanceDec = platformBalanceDec.add(depositDec);

    this.setChainBalance(userState.address, ethers.utils.formatEther(newChainBalanceDec));
    const accountDetails = this.getAccountDetails(userState.address);
    this.putAccountDetails(userState.address, {
      ...accountDetails,
      balance: ethers.utils.formatEther(newPlatformBalanceDec),
    });

    return true;
  }

  async withdrawFromWallet(balance: string): Promise<boolean> {
    const userState = await this.waitForUserState();
    const chainBalance = await this.fetchChainBalance(); // this already checks for login and link
    const platformBalance = '0'; // userState.balance;
    const chainBalanceDec = ethers.utils.parseEther(chainBalance);
    const platformBalanceDec = ethers.utils.parseEther(platformBalance);
    const withdrawDec = ethers.utils.parseEther(balance);

    if (withdrawDec.lte('0.0')) {
      throw new Error('Cannot withdraw zero or less');
    }

    if (withdrawDec.gt(platformBalance)) {
      throw new Error('Cannot withdraw more than available balance');
    }

    await waitForTx(`Approve withdrawing ${balance} from wallet?`);

    const newPlatformBalanceDec = platformBalanceDec.sub(withdrawDec);
    const newChainBalanceDec = chainBalanceDec.add(withdrawDec);

    this.setChainBalance(userState.address, ethers.utils.formatEther(newChainBalanceDec));
    const accountDetails = this.getAccountDetails(userState.address);
    this.putAccountDetails(userState.address, {
      ...accountDetails,
      balance: ethers.utils.formatEther(newPlatformBalanceDec),
    });

    return true;
  }
  async fetchChainBalance(): Promise<string> {
    const linked = await this.checkIfLinked();
    if (!linked) {
      throw new Error('Not linked');
    }

    await this.waitForLogin(); // wait for user dispatches to take place
    const userState = await this.waitForUserState();
    const userDispatch = await this.waitForDispatch();
    const chainBalance = this.chainBalanceMap[userState.address] ?? '0.0';

    // if (userState.chainBalance !== chainBalance) {
    //   userDispatch({
    //     type: UserActionType.UpdateChainBalance,
    //     chainBalance
    //   });
    // }

    return chainBalance;
  }

  setChainBalance(address: string, balance: string): void {
    this.chainBalanceMap[address] = balance;
    this.saveChainBalance();
    Promise.all([this.waitForUserState(), this.waitForDispatch()]).then(([userState, userDispatch]) => {
      if (address === userState.address) {
        // userDispatch({
        //   type: UserActionType.UpdateChainBalance,
        //   chainBalance: balance
        // });
      }
    });
  }

  getChainBalance(address: string, balance: string): string {
    return this.chainBalanceMap[address] ?? '0.0';
  }
  changeSvsProvider(opts?: ChangeSvsProviderOpts): Promise<string> {
    this.providerType = opts.providerType ?? SvsProvider.WalletConnect;
    return Promise.resolve(this.providerType);
  }
  getSvsProvider(): Promise<string> {
    return Promise.resolve(this.providerType);
  }

  async fetchMyStoryTokenForContract(_: { contractAddress: ContractAddress }) {
    return undefined;
  }
}
