import { debug } from '@common/LogWrapper';
import { tokenReplace } from '@common/StringUtils';
import { environment } from '@environment';
import { AlreadySubbedError } from '@errors';
import {
  EmailSubService,
  IsSubscribedOpts,
  socialHandleParams,
  SocialHandleParam,
  SubscribeOpts,
  SubscribeResult,
  IsSubscribedResult,
} from './EmailSubService';

const log = debug('app:services:RestEmailSubService');

enum LocalEndpoint {
  EmailSub = 'emailsub',
}

enum PipelineEndpoint {
  MarketingCampaign = 'marketing/{campaign}/{walletAddress}',
}

function getPipelineEndpoint(
  endpoint: PipelineEndpoint,
  tokens: {
    campaign: string;
    walletAddress: string;
  },
) {
  return `${environment.pipelineBaseUrl}/${tokenReplace(endpoint, tokens)}`;
}

export class RestEmailSubService implements EmailSubService {
  async subscribe(opts: SubscribeOpts): Promise<SubscribeResult> {
    const [emailSub, pipeline] = await Promise.all([
      opts.socialHandles.email ? this.sendToEmailSub(opts) : Promise.resolve(),
      opts.campaign && opts.socialHandles.hotWallet ? this.sendToPipeline(opts) : Promise.resolve(),
    ]);
    return {
      email: Boolean(emailSub),
      socials: Boolean(pipeline),
    };
  }
  async subscribeEmail(opts: SubscribeOpts): Promise<SubscribeResult> {
    return {
      email: await this.sendToEmailSub(opts),
    };
  }
  async subscribeSocials(opts: SubscribeOpts): Promise<SubscribeResult> {
    return {
      socials: await this.sendToPipeline(opts),
    };
  }
  async isSubscribed({ campaign, walletAddress, twitterHandle }: IsSubscribedOpts): Promise<IsSubscribedResult> {
    if (!campaign || !walletAddress) {
      throw new Error('Missing campaign and/or wallet address in options');
    }

    const url = new URL(
      getPipelineEndpoint(PipelineEndpoint.MarketingCampaign, {
        campaign,
        walletAddress,
      }),
    );

    if (twitterHandle) {
      url.searchParams.append(SocialHandleParam.Twitter, twitterHandle);
    }

    const response = await fetch(url.toString());

    if (!response.ok) {
      const errorData = await response.text();
      log('Error response while checking if user is subscribed:', errorData);
      throw new Error('Error response while checking if user is subscribed');
    }

    try {
      const data = await response.json();
      return data ?? { exists: false };
    } catch (e) {
      log('Error parsing response while checking if user is subscribed:', e.message);
      throw new Error('Cannot parse isSubscribed results');
    }
  }

  private async sendToEmailSub(opts: SubscribeOpts): Promise<boolean> {
    if (!opts.socialHandles.email) {
      throw new Error('Missing email on options');
    }
    try {
      const filteredBody = Object.fromEntries(Object.entries(opts.socialHandles).filter(([key]) => socialHandleParams.includes(key as SocialHandleParam)));
      const response = await fetch(LocalEndpoint.EmailSub, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filteredBody),
      });
      if (!response.ok) {
        const errorData = await response.text();
        log('Error response from subscribing email:', errorData);
        throw new Error('Error response from subscribing email');
      }
    } catch (e) {
      log('Error occurred while subscribing email:', e.message);
      throw new Error('Error occurred while subscribing email');
    }

    return true;
  }

  private async sendToPipeline(opts: SubscribeOpts): Promise<boolean> {
    if (!opts.campaign || !opts.socialHandles.hotWallet) {
      throw new Error('Missing campaign and/or hotWallet in options');
    }

    const socialHandles = Object.fromEntries(Object.entries(opts.socialHandles).filter(([key]) => socialHandleParams.includes(key as SocialHandleParam)));
    const urlStr = getPipelineEndpoint(PipelineEndpoint.MarketingCampaign, {
      campaign: opts.campaign,
      walletAddress: opts.socialHandles.hotWallet,
    });

    const url = new URL(urlStr);
    if (opts.isCharPass) {
      url.searchParams.append('isCharPass', '1');
    }

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: 'no-cache',
      body: JSON.stringify({ socialHandles, token: opts.captchaToken }),
    });
    if (!response.ok) {
      const errorData = await response.text();
      log('Error response from adding socials:', errorData, response.status);
      if (response.status === 409) {
        throw new AlreadySubbedError(
          [
            'This wallet or Twitter account was already entered in this raffle.',
            `raffle: ${opts.campaign}`,
            `wallet address: ${opts.socialHandles.hotWallet}`,
            `twitter: ${opts.socialHandles.twitterHandle}`,
          ].join('\n'),
        );
      }
      throw new Error('Error response from adding socials');
    }

    return true;
  }
}
