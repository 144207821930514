import { environment } from '@environment';
import { MintActionType, MintPageState, MintTwitterAvailState, useMintDispatch, useMintState } from '@context/mint/MintContext';
import React, { FormEvent, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { SubscribeOpts } from '@services/emailsub/EmailSubService';
import { mainSuite } from '@services/ServiceFactory';
import { useUserState } from '@services/user/UserContext';
import { SvsErrors, SvsNavbarEvent, SvsProvider, TwtAuthVersion } from '@storyverseco/svs-navbar';
import { AnalyticsEventName, ShareType } from '@services/analytics/AnalyticsEventName';
import { minutesToMs, secondsToMs } from '@common/NumberUtils';
import { debug } from '@common/LogWrapper';
import { useUserHook } from '@hooks/useUserHook';
import { TwtAuthState } from '@common/TwtAuthState';
import { useAppState } from '@context/AppContext';
import { StepBox } from '../components/StepBox';
import Sale, { SaleFollowSocial, SaleSocialType } from '@common/Sale';
import { SearchParam } from '@common/SearchParam';

const log = debug('app:pages:MintSubPage:CharPassAuthSection:TwitterAuthButton');

enum SubscribeState {
  Idle,
  Checking,
  NotSubbed,
  Subbed,
}

const getTwitterStepTitle = (followSocials: SaleFollowSocial[]) => (
  <>
    Follow,{' '}
    {followSocials
      .filter((social) => social.type === SaleSocialType.Twitter)
      .map((social) => (
        <React.Fragment key={`step-title-${social.type}-${social.userId}`}>
          <a href={`https://twitter.com/${social.userId}`} target="_blank">
            {social.userId || ''}
          </a>{' '}
        </React.Fragment>
      ))}
  </>
);

export function TwitterAuthStepButton({
  showSpinner,
  onAuthorized,
  charPass,
}: {
  showSpinner: (show: boolean) => void;
  onAuthorized: (authorized: boolean) => void;
  charPass?: boolean;
}) {
  const appState = useAppState();
  const mintState = useMintState();
  const mintDispatch = useMintDispatch();
  const userState = useUserState();
  const { emailSubService, delegatecashService, analyticsService, chainalysisService, navbarService } = mainSuite;
  const { twitterService } = navbarService.api;
  const { wallet, error: walletConnectError, ready, logIn, initted, providerReady } = useUserHook({ providerType: SvsProvider.WalletConnect });
  const [subscribeState, setSubscribeState] = useState(SubscribeState.Idle);
  const [isTwitterVerified, setIsTwitterVerified] = useState(false);
  const [redirectAuthProcessed, setRedirectAuthProcessed] = useState(false);
  const [twitterVerifying, setTwitterVerifying] = useState(false);
  const [triedVerifyOnce, setTriedVerifyOnce] = useState(false);
  const [remoteTwStatus, setRemoteTwStatus] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const isConnected = ready && Boolean(wallet?.flags.loggedIn);
  const address = isConnected ? wallet?.address : null;

  function reportError(opts: { message: string; stepId: string }) {
    setErrorMsg(opts.message);
    analyticsService.track(AnalyticsEventName.GenericError, {
      stepId: opts.stepId,
      saleId: mintState.sale?.saleId,
    });
  }

  // check for subscribed status
  useEffect(() => {
    if (!isConnected) {
      return;
    }
    if (subscribeState !== SubscribeState.Idle) {
      return;
    }

    twitterService.auth
      .get()
      .then((user) => {
        log('twitter user', {
          user,
          campaign: mintState.sale?.marketingCampaign,
          walletAddress: address,
        });
        if (!user?.handle) {
          return emailSubService.isSubscribed({
            campaign: mintState.sale?.marketingCampaign,
            walletAddress: address,
          });
        }

        return emailSubService.isSubscribed({
          campaign: mintState.sale?.marketingCampaign,
          walletAddress: address,
          twitterHandle: user.handle,
        });
      })
      .then((result) => {
        log('isSubscribed result', result);
        //setSubscribeState(SubscribeState.Subbed)
        setSubscribeState(result.exists ? SubscribeState.Subbed : SubscribeState.NotSubbed);
        if ('twitterStatus' in result) {
          setRemoteTwStatus(result.twitterStatus);
        }
      })
      .catch((e) => {
        log('Error occurred while checking if subscribed', e);
        reportError({
          message: 'Error occurred while checking if subscribed',
          stepId: 'check-subscribed-error',
        });
      });
  }, [isConnected, address, subscribeState]);

  // if subscribed, automatically go to "congrats" section
  useEffect(() => {
    showSpinner(false);
    if (subscribeState === SubscribeState.Subbed) {
      setIsTwitterVerified(true);
      onAuthorized(true);
    }
  }, [subscribeState]);

  const onTwitterVerify = useCallback(async () => {
    if (isTwitterVerified) {
      return;
    }

    // skipping auth if this param exists
    if (appState.initialSearchParams.has(SearchParam.SkipAuth)) {
      // simulate verifying
      setTwitterVerifying(true);
      setTriedVerifyOnce(true);
      setTimeout(() => {
        setTwitterVerifying(false);
        setIsTwitterVerified(true);
        onAuthorized(true);
      }, 1500);
      setErrorMsg('');

      // purposely not awaiting. we still want the twitter user if they happen to
      // be logged in with one
      twitterService.auth.get().then((user) => {
        analyticsService.track(AnalyticsEventName.ButtonPress, {
          buttonName: 'twitterVerify',
          saleId: mintState.sale?.saleId,
          skippedAuth: true,
        });

        // twitter handle is required for marketing, so just don't do anything if it doesn't exist
        if (!user) {
          return;
        }
        const subscribeBody: SubscribeOpts = {
          campaign: mintState.sale?.marketingCampaign,
          socialHandles: {
            hotWallet: address,
            twitterHandle: user.handle,
          },
          isCharPass: charPass,
        };
        emailSubService.subscribe(subscribeBody); // purposely not awaiting
      });
      return;
    }

    setTwitterVerifying(true);
    const verifyingId = setTimeout(() => {
      setTwitterVerifying(false);
    }, 5000);
    analyticsService.track(AnalyticsEventName.ButtonPress, {
      buttonName: 'twitterVerify',
      saleId: mintState.sale?.saleId,
      skippedAuth: false,
    });
    setErrorMsg('');

    // need to check if we have permissions first
    let loggedIn = await twitterService.auth.canWrite();
    if (!loggedIn) {
      // technically this will await forever, but we'll pretend it returns a user (or null)
      const user = await twitterService.auth.logIn({ authVersion: TwtAuthVersion.V1Write });
      loggedIn = Boolean(user);
    }

    if (!loggedIn) {
      // should not reach here but just in case
      // we're making an error here for sentry to pick up through the wrapped debug log
      const error = new Error('twitterService auth logIn somehow returned no user during a logged out session');
      log('unexpected twitter service state:', error);
      reportError({
        message: 'Needs Twitter authorization.',
        stepId: 'twitter-verify-auth-error',
      });
      return;
    }

    const user = await twitterService.auth.get();
    const subscribeBody: SubscribeOpts = {
      campaign: mintState.sale?.marketingCampaign,
      socialHandles: {
        hotWallet: address,
        twitterHandle: user?.handle,
      },
      isCharPass: charPass,
    };
    await emailSubService.subscribe(subscribeBody);

    // get twitter usernames from sale
    const userNames =
      mintState.sale?.followSocials
        ?.filter((social) => social.type === SaleSocialType.Twitter && social.required)
        .map((social) => social.userId.toLowerCase()) ?? [];
    setTriedVerifyOnce(true);
    try {
      const followMap = await twitterService.fetchIsFollowingAll({ userNames });
      const followMapLc: Record<string, boolean> = Object.entries(followMap).reduce(
        (map, [key, value]) => ({
          ...map,
          [key.toLowerCase()]: value,
        }),
        {},
      );

      // if we're at this point, then the twitter service is working fine
      mintDispatch({
        type: MintActionType.UpdateTwitterAvailState,
        twitterAvailState: MintTwitterAvailState.Available,
      });

      if (!userNames.every((user) => followMapLc[user])) {
        throw new Error(`Follow all ${userNames.length} Twitter accounts to register.`);
      }
      setIsTwitterVerified(true);
    } catch (e) {
      // if it's an auth error, assume twitter is being weird and approve anyway
      if (SvsErrors.SvsAuthError.isError(e)) {
        setIsTwitterVerified(true); // set false later. this is to disable while twitter has our and am illion other apps broken
        // https://twittercommunity.com/t/v2-suddenly-getting-client-not-enrolled-today/195456/63
        // also eat the error for now so as to not spook the normies
        // reportError({
        //   message: e.message,
        //   stepId: 'twitter-verify-error',
        // });
        onAuthorized(true);

        // twitter service is unavailable
        // @TODO: twitter api's following endpoint is gone. disabling to allow twitter video share to work
        // mintDispatch({
        //   type: MintActionType.UpdateTwitterAvailState,
        //   twitterAvailState: MintTwitterAvailState.Unavailable,
        // });
      } else {
        setIsTwitterVerified(false);
        onAuthorized(false);
        reportError({
          message: e.message,
          stepId: 'twitter-verify-error',
        });
      }
    } finally {
      setTwitterVerifying(false);
      clearTimeout(verifyingId);
    }
  }, [isTwitterVerified, triedVerifyOnce, mintState, address, appState.initialSearchParams]);

  // if twitter auth param was present, we should auto verify twitter followers
  useEffect(() => {
    if (isTwitterVerified) {
      return;
    }

    if (triedVerifyOnce) {
      return;
    }

    if (!isConnected) {
      return;
    }

    // only if recently logged in
    twitterService.auth
      .recentlyLoggedIn()
      .then((recentlyLoggedIn) => {
        if (recentlyLoggedIn) {
          return twitterService.auth.canWrite();
        } else {
          return false;
        }
      })
      .then((loggedIn) => {
        if (loggedIn) {
          log('auto twitter verify');
          return onTwitterVerify();
        }
      });
  }, [isConnected, isTwitterVerified, onTwitterVerify, triedVerifyOnce]);

  const socials = mintState.sale?.followSocials || [
    {
      type: 'twitter',
      userId: 'storyverse_xyz',
      required: true,
    },
  ];
  const title = getTwitterStepTitle(socials.filter((social) => social.type === SaleSocialType.Twitter && social.required));
  const subtitle = mintState.sale?.saleMedia?.twitterHintCopy ?? 'Connect your Twitter';
  return (
    <StepBox
      disabled={!delegatecashService.isLoggedIn()}
      btn={{
        show: true,
        disabled: isTwitterVerified || !delegatecashService.isLoggedIn() || twitterVerifying,
        label: twitterVerifying ? 'CONNECTING...' : isTwitterVerified ? 'CONNECTED' : 'CONNECT',
        showArrow: !isTwitterVerified,
        onClick: onTwitterVerify,
      }}
      title={title}
      subTitle={isTwitterVerified ? 'Connected' : subtitle}
      icon={'twitter'}
      done={isTwitterVerified}
    />
  );
}
