import { SvsBridge, SvsWalletService, SvsWriterApi } from '@storyverseco/svs-navbar';
import { AdminService } from '../admin/AdminService';
import { AnalyticsService } from '../analytics/AnalyticsService';
import { ApiType } from '../ApiType';
import { ChainalysisService } from '../chainalysis/ChainalysisService';
import { EmailSubService } from '../emailsub/EmailSubService';
import { MetadataService } from '../metadata/MetadataService';
import { PlotsService } from '../plots/PlotsService';
import { SaleService } from '../sale/SaleService';
import { TokenproofService } from '../tokenproof/TokenproofService';
import { UserService } from '../user/UserService';
import { DelegatecashService } from '../delegatecash/DelegatecashService';
import { Suite } from './Suite';

export abstract class BaseSuite implements Suite {
  abstract readonly apiType?: ApiType;
  mainSuite?: Suite;

  get userService(): UserService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get adminService(): AdminService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get metadataService(): MetadataService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get plotsService(): PlotsService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get analyticsService(): AnalyticsService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get chainalysisService(): ChainalysisService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get navbarService(): SvsBridge {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get walletService(): SvsWalletService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get writerService(): SvsWriterApi {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get saleService(): SaleService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get tokenproofService(): TokenproofService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
  get emailSubService(): EmailSubService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }

  get delegatecashService(): DelegatecashService {
    throw new Error(`Unsupported api type "${this.apiType}"`);
  }
}
